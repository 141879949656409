<template>
  <ul role="tablist" class="wizard-nav wizard-nav-pills">
    <li v-for="key,index in steps" :class="stepClass(index +1)">
        <span class="number"><strong>{{ index + 1 }}</strong></span> <span class="title">{{ $t('nav.'+key) }}</span>
    </li>
  </ul>
</template>

<script>
  import {mapState} from 'vuex';
	export default {
        name: 'signup-nav',
        data() {
          return {
            steps: [
              'general',
              'medical',
              'personal',
              'final',
            ]
          }
        },    
        methods: {
          stepClass(i) {  
                if(i == this.stepState){
                    return "active";
                }else if(i < this.stepState){
                    return "done";
                }else{
                    return;
                }
            },
        }, 
        computed: {
            ...mapState('user', [
                'stepState',
			  ]),
            
        },
        props: {
            tab: {
                type: Object,
                default: () => {
                }
            },
            transition: {
                type: String,
                default: ''
            },
            index: {
                type: Number,
                default: 1,
            }
        }
	}
</script>
