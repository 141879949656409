export default {
  config: {},
  get (item) {
    if (Object.keys(this.config).length === 0) {
      let configEl = document.getElementById('config')
			// this.config = JSON.parse(configEl.innerHTML);
    }
    return this.config[item]
  }
}
