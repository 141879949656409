import _ from 'lodash'

const vuexMixin = {
  methods: {
    fillDataFromStore (store) {
      for (var key in store) {
                // if array clonedeep the array
        if (Array.isArray(store[key])) {
          this[key] = _.clone(store[key], true)
        } else if (store[key] instanceof (Object)) {
                    // if its object also deep clone it
          this[key] = _.clone(store[key], true)
        } else {
          this[key] = store[key]
        }
      }
    }
  }
}

export default vuexMixin
