<template>
	<div class="main">
		<div class="container">
			<div class="row">
				<div class="col-md-8 col-md-offset-2">

					<div class="wrapper wrapper-small">
						<div class="wrapper-top">
							<div class="app-header">
								<h1 class="heading">{{ $t("bijna18.heading") }}</h1>
							</div>
						</div>
						<div class="wrapper-center">
							<div class="app-content app-content-centered">
								<transition
									name="fade-in-out"
									enter-active-class="fadeIn"
									leave-active-class="fadeOut"
									mode="out-in">
									<router-view></router-view>
								</transition>


							</div>
						</div>
						<div class="wrapper-bottom">
							<div class="app-footer app-footer-centered">
								<span v-html="$t('footer.link')"></span>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import VeeValidate from 'vee-validate';
	import {mapState} from 'vuex';
	import * as types from '../store/mutation-types';

	export default {
        name: 'bijna18',
		data() {
            return {
				email: "",
				submitted: false,
            }
		},

		computed: {
           	...mapState ('app', [
				'locale'
			]),
		},
	}
</script>
