<template>
	<div>
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="progress">
						<div class="progress-bg" v-bind:style="{ width: this.progress + '%'}">
							<span class="progress-fg">{{ this.progress }}%</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	<div class="main">
		<div class="container">
			<div class="row">
				<div class="col-md-12 wizard-navigation">
          <form-nav>
          </form-nav>
          <div class="wrapper">
            <router-view>
            </router-view>      
					</div>
                    <div v-if="APUrl">
                        <img :src="APUrl" width="0" height="0" border="0">
                    </div>
                    <div v-if="APUrlTwo">
                        <img :src="APUrlTwo" width="0" height="0" border="0">
                    </div>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
  import {mapState} from 'vuex';
  import $ from 'jquery';
  import * as types from '../store/mutation-types';
  import _ from 'lodash';
  import formNav from './signup/SignupNavigation.vue';
  
    
	export default {
        name: 'signup',
        data() {
            return {
                "APUrl": false,
                "APUrlTwo": false,
            }
        },
		components: {
          formNav,
    },
    methods: {
      setProgress(start = 0){
        var values = this.$el.querySelectorAll('[data-progress]');
        const progressTick = 25 / values.length;
        var progress = start;
        for (let i = 0; i < values.length; i++) {
          let element = values[i];
          if(element.type == "radio") {
            let htmlNodes = document.getElementsByName(element.name);
            let radioButtonsArray = Array.from(htmlNodes);

            let isAnyRadioButtonChecked = radioButtonsArray.some(element => element.checked);
            
            if(isAnyRadioButtonChecked) {
              progress = progress + progressTick;
            }
          } else{
            if(element.value != "") {
              progress = progress + progressTick;
            }
          }
        };
        this.$store.commit('user/'+types.SET_PROGRESS, Math.round(progress));
      },
      triggerGaEvent(cat, action, label) {
        if(process.env.VUE_APP_ENV == 'production') {
          window.dataLayer.push({
            'event': 'form_interactie',
            'Cat' : cat,
            'Action': action,
            'Label' : label,
          });
        } else{
          console.log('Cat: "' + cat + '" Action: "' + action + '" Label: "' + label + "\"");
        }
      },
      toggleCollapse(target) {
        let element = document.getElementById(target)
        if(element.className == "show")
        {
          element.className = "hide";
        }
        else
        {
          element.className = "show";
        }
      },
      scrollTo: function(element) {
          if (element) {
              let $element = $(element);
              if ($element.parents('.error-scroll-pos').length) { // bind a class to to desired scroll position
                  window.scrollTo(0, $element.parents('.error-scroll-pos').offset().top - 50); // 50 as extra offset
              }
          } else {
              window.scrollTo(0,0); // previously
              // document.body.scrollTop = document.documentElement.scrollTop = 0;
          }
      },
    },
    computed: {
      ...mapState('user', [
        'meta',
        'progress',
      ]),
    }
	}
</script>

<style>
.progress {
    margin:0 0 20px 0;
    width:100%;
    background: white;
    position: relative;
    border-radius: 7px;
    overflow:hidden;
    height: auto;
}

.progress-bg {
        width:0;
        /* @extend .blue_pattern; */
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        color:white;
        padding-right:10px;
        padding:0px 10px;
        display: inline-block;
        margin:0;
        text-align: right;
        transition: width ease-in-out 0.25s;
    }
.progress-fg {
        color:white;
        display: inline-block;
        /* @extend .semibold ; */
        font-size: 0.875em;
    }
</style>
