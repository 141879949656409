<template>
  <div class="wrapper-center" v-on:change="setProgress()">
    <Form
      @submit="onSubmit"
      :validation-schema="schema"
      v-slot="{ errors }"
      v-on:change="setProgress()"
      id="final"
      class="validate"
    >
      <modal ref="modal">
        <template #content>
          <div class="row">
            <div class="col-md-12">
              <div class="modal-content--side modal-content--side--left">
                <div style="display: flex; justify-content: space-between">
                  <h2 class="modal__title">{{ $t("ideal.issuerTitle") }}</h2>
                  <span
                    v-on:click.prevent="closeModal"
                    style="cursor: pointer"
                    class="fa fa-times"
                  ></span>
                </div>
                <div v-html="$t('ideal.issuerText')"></div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="idin-messag ideal-message">
                <div class="media">
                  <div class="media-left">
                    <img
                      class="media-object"
                      src="/assets/images/ideal.png"
                      alt="iDeal"
                    />
                  </div>
                  <div class="media-body">
                    <h3 class="media-heading">{{ $t("ideal.title") }}</h3>
                    <p>{{ $t("ideal.subTitle") }}</p>
                  </div>
                </div>
                <div class="form-group select-group">
                  <label for="choose_bank">
                    <span
                      class="placeholder"
                      :class="{ hidden: issuerNotDefault }"
                      >{{ $t("ideal.selectIssuer") }}</span
                    >
                  </label>
                  <Field
                    as="select"
                    autocomplete="off"
                    class="form-control select-bank required"
                    name="issuer"
                    id="choose_bank"
                    data-msg="Verplicht"
                    v-model="issuer"
                  >
                    <optgroup label="Nederland">
                      <option
                        v-for="issuer in issuersNL"
                        :value="issuer.issuerId"
                        :key="issuer.issuerId"
                      >
                        {{ issuer.name }}
                      </option>
                    </optgroup>
                  </Field>
                  <span class="error" v-show="errorIssuer">{{
                    $t("error.required")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div
                class="modal-content--side modal-content--side--right modal-content--bg modal-content--bg--blue modal-content--vertical modal-content--vertical--middle"
              >
                <div class="modal-content__box">
                  <a
                    v-on:click.once.prevent="proceedPayment"
                    class="btn button orange"
                    :class="{ 'is-loading': loadingIssuer }"
                  >
                    <span class="text">{{ $t("button.proceedPayment") }}</span>
                    <span class="loader">
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </template>
      </modal>
      <div class="hidden print-logo" aria-hidden="true">
        <img
          src="/assets/images/logo-nl-black.png"
          alt="Logo Matchis NL print"
          class="print-logo-nl"
        />
        <img
          src="/assets/images/logo-en-black.png"
          alt="Logo Matchis EN print"
          class="print-logo-en"
        />
      </div>
      <h2>{{ $t("nav.final") }}</h2>
      <a
        href="#"
        class="edit print fa fa-print"
        onclick="window.print();return false;"
        ><span class="hidden">Print pagina</span></a
      >
      <div class="row">
        <div class="fieldset input_style">
          <!-- add class: error -->
          <div
            class="form-group result-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 error"
          >
            <div class="row">
              <div class="col-md-12">
                <span class="label"
                  >{{ $t("label.step") }} 1 - {{ $t("nav.general") }}</span
                >
                <a
                  href="#"
                  class="edit fa fa-pencil"
                  v-on:click.prevent="$router.push('/algemeen')"
                  @click="
                    $parent.$parent.triggerGaEvent(
                      'Definitief',
                      'Knop: Stap 1 bewerken',
                      'Ja'
                    )
                  "
                  ><span class="hidden">Bewerken</span></a
                >
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <p>
                          {{ $t("label.residentNl") }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ boolText(general.residentNl) }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <p>
                          {{ $t("label.birthDate") }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ birthday }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <p>
                          {{ $t("label.height") }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ general.height }} cm</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <p>
                          {{ $t("label.weight") }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ general.weight }} kg</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <p>
                          {{ $t("label.originParents") }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ otherContinent }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-12 border-bottom"
                    v-if="general.otherContinent == 'true'"
                  >
                    <div class="row">
                      <div class="col-md-8">
                        <p>{{ $t("label.originFather") }}</p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ originFather }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-12 border-bottom"
                    v-if="general.otherContinent == 'true'"
                  >
                    <div class="row">
                      <div class="col-md-8">
                        <p>{{ $t("label.originMother") }}</p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ originMother }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-12 border-bottom"
                    v-if="general.validEventCode"
                  >
                    <div class="row">
                      <div class="col-md-8">
                        <p>{{ $t("label.eventCode") }}</p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ general.eventCode }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="form-group result-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2"
          >
            <div class="row">
              <div class="col-md-12">
                <span class="label"
                  >{{ $t("label.step") }} 2 - {{ $t("nav.medical") }}</span
                >
                <a
                  href="#"
                  class="edit fa fa-pencil"
                  v-on:click.prevent="$router.push('/medisch')"
                  @click="
                    $parent.$parent.triggerGaEvent(
                      'Definitief',
                      'Knop: Stap 2 bewerken',
                      'Ja'
                    )
                  "
                  ><span class="hidden">Bewerken</span></a
                >
              </div>
              <div class="col-md-12 border-bottom">
                <div class="row">
                  <div class="col-md-8">
                    <p>{{ $t("label.medicalAffection") }}</p>
                  </div>
                  <div class="col-md-4">
                    <p>
                      <strong>{{ boolText(medical.medicalAffection) }}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="col-md-12 border-bottom"
                v-if="medical.medications.length"
              >
                <div class="row">
                  <div class="col-md-8">
                    <p>Medicijnengebruik:</p>
                  </div>
                </div>
                <div class="row">
                  <div v-for="medication in medical.medications" :key="medication.id">
                    <div class="col-md-4">
                      <p>{{ medication.name }}</p>
                    </div>
                    <div class="col-md-4">
                      <p>{{ medication.reason }}</p>
                    </div>
                    <div class="col-md-4">
                      <p>{{ medication.frequency }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="form-group result-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2"
          >
            <div class="row">
              <div class="col-md-12">
                <span class="label"
                  >{{ $t("label.step") }} 3 - {{ $t("nav.personal") }}</span
                >
                <a
                  href="#"
                  class="edit fa fa-pencil"
                  v-on:click.prevent="$router.push('/persoonlijk')"
                  @click="
                    $parent.$parent.triggerGaEvent(
                      'Definitief',
                      'Knop: Stap 3 bewerken',
                      'Ja'
                    )
                  "
                  ><span class="hidden">Bewerken</span></a
                >
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <p>
                          {{ $t("label.gender") }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ gender }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <p>
                          {{ $t("label.firstName") }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ personal.firstName }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <p>
                          {{ $t("label.lastName") }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ personal.lastName }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-12 border-bottom"
                    v-show="personal.passportName"
                  >
                    <div class="row">
                      <div class="col-md-8">
                        <p>
                          {{ $t("label.passportName") }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ personal.passportName }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-12 border-bottom"
                    v-show="personal.initialsPassport"
                  >
                    <div class="row">
                      <div class="col-md-8">
                        <p>{{ $t("label.initialsPassport") }}</p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ personal.initialsPassport }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <p>
                          {{ $t("label.email") }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ personal.email }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <p>
                          {{ $t("label.phone") }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ personal.phone }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <p>
                          {{ $t("label.postcode") }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ postalcode }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <p>
                          {{ $t("label.address") }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ address }} </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <p>
                          {{ $t("label.city") }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          <strong>{{ personal.city }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="form-group result-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2"
          >
            <div class="row">
              <!-- Donor -->
              <fieldset class="input_style">
                  <!-- add class: error -->
                  <div class="form-group radio-group col-md-12">
                      <div class="row error-scroll-pos">
                          <div class="col-md-12">
                              <span class="label">{{ $t('label.bloodPlasmaDonor') }}<span class="fa fa-info-circle toggle" @click="$parent.$parent.toggleCollapse('bloodPlasmaDonor')"></span></span>
                              <!-- element to collapse -->
                              <div id="bloodPlasmaDonor" class="collapse" style="">
                                <div class="card">
                                  <div class="card-body">
                                      <p>{{ $t('tooltip.bloodPlasmaDonor') }}</p>
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div class="input_wrap col-md-2">
                              <Field type="radio" name="bloodPlasmaDonor" id="geen_donor" value="0" v-model="bloodPlasmaDonor" data-progress="donor" @change="$parent.$parent.triggerGaEvent('Definitief', 'Vraag: ' + $t('label.bloodPlasmaDonor'), 'Nee')" />
                              <label for="geen_donor">
                                  {{ $t('option.no') }}
                              </label>
                          </div>
                          <div class="input_wrap col-md-2">
                              <Field type="radio" name="bloodPlasmaDonor" id="wel_donor" value="1" v-model="bloodPlasmaDonor" @change="$parent.$parent.triggerGaEvent('Definitief', 'Vraag: ' + $t('label.bloodPlasmaDonor'), 'Ja')" />
                              <label for="wel_donor">
                                  {{ $t('option.yes') }}
                              </label>
                          </div>
                          <span class="error" v-if="errors.bloodPlasmaDonor">
                            {{ $t('error.required') }}
                          </span>
                          <div class="col-md-12 pl-0 pt-1" v-show="bloodPlasmaDonor == 1">
                            <div class="input_wrap checkbox-group col-md-12 error-scroll-pos">
                              <span class="label">{{ $t('label.sanquinConsent') }}<span class="fa fa-info-circle toggle" @click="$parent.$parent.toggleCollapse('sanquinSetConsentCollapse')"></span></span>
                              <!-- element to collapse -->
                              <div id="sanquinSetConsentCollapse" class="collapse" style="">
                                <div class="card">
                                  <div class="card-body">
                                      <p>{{ $t('tooltip.sanquinConsent') }}</p>
                                  </div>
                                </div>
                              </div>

                              <Field
                                type="checkbox"
                                name="sanquinSetConsent"
                                id="sanquinSetConsent"
                                :checked="sanquinSetConsent"
                                value="1"
                                form="controleren"
                                v-model="sanquinSetConsent"
                                data-progress="sanquinSetConsent"
                                @change="
                                  $parent.$parent.triggerGaEvent(
                                    'Definitief',
                                    'Sanquin akkoord',
                                    sanquinSetConsent ? 'Ja' : 'Nee'
                                  )
                                "
                              />
                              <label
                                for="sanquinSetConsent"
                                data-test="sanquinSetConsent"
                                class="more-content"
                              >
                                {{ $t("label.sanquinSetConsent") }}
                              </label>
                              <span class="error" v-show="errors.sanquinSetConsent">{{
                                $t("error.required")
                              }}</span>
                            </div>
                          </div>
                      </div>
                  </div>
              </fieldset>
              <!-- /Donor -->              
              <div class="col-md-12">
                <span class="label">{{ $t("title.signup_approval") }}</span>
              </div>
              <div class="input_wrap checkbox-group col-md-12 error-scroll-pos">
                <Field
                  type="checkbox"
                  name="approvalSignup"
                  id="approvalSignup"
                  value="1"
                  :checked="approvalSignup"
                  form="controleren"
                  v-model="approvalSignup"
                  data-progress="approvalSignup"
                  @change="
                    $parent.$parent.triggerGaEvent(
                      'Definitief',
                      'Ik verklaar dat ik de informatie over stamceldonatie heb gelezen',
                      'Ja'
                    )
                  "
                />
                <label
                  for="approvalSignup"
                  data-test="approvalSignup"
                  class="more-content"
                >
                  {{ $t("label.signupApproval") }}
                </label>
                <span class="error" v-show="errors.approvalSignup">
                  {{ $t("error.required") }}</span
                >
              </div>

              <div class="col-md-12">
                <div class="form-control toelichting required">
                  <div v-html="$t('text.conditions')"></div>
                </div>
              </div>
              <div class="input_wrap checkbox-group col-md-12 error-scroll-pos">
                <Field
                  type="checkbox"
                  name="acceptConditions"
                  id="acceptConditions"
                  :checked="acceptConditions"
                  value="1"
                  form="controleren"
                  v-model="acceptConditions"
                  data-progress="acceptConditions"
                  @change="
                    $parent.$parent.triggerGaEvent(
                      'Definitief',
                      'Ik ga akkoord',
                      'Ja'
                    )
                  "
                />
                <label
                  for="acceptConditions"
                  data-test="acceptConditions"
                  class="more-content"
                >
                  {{ $t("label.conditions") }}
                </label>
                <span class="error" v-show="errors.acceptConditions">{{
                  $t("error.required")
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="form-group result-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2"
          >
            <div class="row">
              <div class="col-md-12">
                <button
                  class="btn green submit btn-large g-recaptcha"
                  :class="{ 'is-loading': loadingStates.user }"
                  :disabled="loadingStates.user == true"
                >
                  <span class="text">{{ submitButton }}</span>
                  <span class="loader">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div v-if="APUrl">
            <img :src="APUrl" width="0" height="0" border="0" />
          </div>
          <div v-if="APUrlTwo">
            <img :src="APUrlTwo" width="0" height="0" border="0" />
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>
<script>
import Vue from "vue";
import $ from "jquery";
import { mapState } from "vuex";
import CountriesNL from "../../../data/countries_nl.json";
import CountriesEN from "../../../data/countries_en.json";
import * as types from "../../../store/mutation-types";
import _ from "lodash";
import vuexMixin from "../../../mixins/vuexMixin";
import Modal from "./../../modal/Modal.vue";

import { Form, Field } from "vee-validate";
import * as yup from "yup";

export default {
  name: "form-final",
  components: {
    Modal,
    Form,
    Field,
  },
  data() {
    return {
      APUrl: false,
      APUrlTwo: false,
      issuer: "",
      errorIssuer: false,
      approvalSignup: "",
      acceptConditions: "",
      countries: CountriesNL, // defaults to NL
      loadingIssuer: false,
      bloodPlasmaDonor: '',
      sanquinSetConsent: '',
      bloodPlasmaDonorResult: '',
      sanquinSetConsentResult: '',
    };
  },
  mixins: [vuexMixin],
  setup() {
    const schema = yup.object({
      approvalSignup: yup.string().required(),
      acceptConditions: yup.string().required(),
      // medicalAffection: yup.string().required(),
      bloodPlasmaDonor: yup.string().required(),
    });
    return {
      schema,
    };
  },
  mounted() {
    this.setProgress(75);
  },
  created() {
    this.fillDataFromStore();
    this.changeCountryList(this.locale);
  },
  watch: {
    locale(locale) {
      this.changeCountryList(this.locale);
    },
    bloodPlasmaDonor: function(val) {
      if (val == '0') {
        this.bloodPlasmaDonor = false;
        this.bloodPlasmaDonorResult = 'nee';
      } else {
        this.bloodPlasmaDonor = true;
        this.bloodPlasmaDonorResult = 'ja';
      }

      this.sanquinSetConsent = false;
      this.sanquinSetConsentResult = 'nee';
    },
    sanquinSetConsent: function(val) {
      if (val == '1') {
        this.sanquinSetConsent = true;
        this.sanquinSetConsentResult = 'ja';
      } else {
        this.sanquinSetConsent = false;
        this.sanquinSetConsentResult = 'nee';
      }
    },
  },
  methods: {
    onSubmit() {
      this.finalValidation();
    },
    finalValidation: function () {
      this.$store.commit("app/" + types.LOADING_USER);
      // clone it, else it would still have references to the component data attributes..@todo clean this up
      this.$store.commit("user/" + types.SET_FINAL, _.clone(this._data, true));

      // Dispatch event to GTM
      this.$gtm.trackEvent({
        event: "registration-confirmed",
        value: true,
        noninteraction: true,
      });

      // If we have a paying user show modal and abort current flow
      // After redirect from issuer dispatch after success
      if (this.general.payingUser) {
        this.$refs["modal"].open();
        return false;
      }
      this.$store
        .dispatch("user/saveDonor", this.user)
        .then((response) => {
          this.$store.commit("user/" + types.RESET_NOTICE);
          let redirecturl = "https://gift.matchis.nl/registratie";
          let redirectquery = {};
          if (response.message == "created") {
            // Redirect to gift matchis retain UTM data.
            this.$store.commit("user/" + types.RESET_USER);
            this.$store.commit("app/" + types.USER_LOADED);
            redirectquery.medium = this.$cookies.get("_uc_utm_medium");
            redirectquery.campaign = this.$cookies.get("_uc_utm_campaign");
            redirectquery.source = this.$cookies.get("_uc_utm_source");
          }
          redirectquery.reg = 1;
          let finalURL = redirecturl + "?";
          for (const key in redirectquery) {
            if (redirectquery[key]) {
              finalURL += `${key}=${redirectquery[key]}&`;
            }
          }
          finalURL = finalURL.slice(0, -1);
          setTimeout(function () {
            window.location.href = finalURL;
          }, 2000);
          return false;
        })
        .catch((error) => {
          // errors from backend show on top
          this.$store.commit("app/" + types.USER_LOADED);
          this.scrollTo();
          console.log(error);
          this.toErrorArray(error.errors);
          return false;
        });
    },
    stringToHash(string) {
      var hash = 0;

      if (string.length == 0) return hash;

      for (var i = 0; i < string.length; i++) {
        var char = string.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
      }
      return hash;
    },
    setProgress() {
      this.$parent.$parent.setProgress(75);
    },
    submit() {
      var wizard = this.$parent.$parent.$refs.wizard;
      wizard.nextTab();
    },
    closeModal() {
      this.$refs.modal.close();
      this.$store.commit("app/" + types.USER_LOADED);
      this.loadingIssuer = false;
    },
    proceedPayment() {
      // custom validation here due to vee validate restrictions and form wizard
      if (this.issuerNotDefault) {
        this.errorIssuer = false;
        this.loadingIssuer = true;

        this.$store
          .dispatch("user/saveDonor", this.user)
          .then((response) => {
            this.$store.commit("user/" + types.RESET_NOTICE);
            if (
              response.message == "created" &&
              _.get(response, "data.redirectUrl", false)
            ) {
              this.loadingIssuer = false;

              this.$store.commit("user/" + types.RESET_USER);
              this.$store.commit("app/" + types.USER_LOADED);
              // Redirect to issuer
              setTimeout(function () {
                window.location.href = response.data.redirectUrl;
              }, 2000);
            }
            return false;
          })
          .catch((error) => {
            console.log(error);
            this.closeModal();
            this.scrollTo();
            this.toErrorArray(error.errors);
            return false;
          });
      } else {
        // show error
        this.errorIssuer = true;
      }
    },
    scrollTo: function (element) {
      if (element) {
        let $element = $(element);
        if ($element.parents(".error-scroll-pos").length) {
          // bind a class to to desired scroll position
          window.scrollTo(
            0,
            $element.parents(".error-scroll-pos").offset().top - 50
          ); // 50 as extra offset
        }
      } else {
        window.scrollTo(0, 0); // previously
        // document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    },
    toErrorArray(errors) {
      var errorArray = [];
      var val;
      for (val of errors) {
        var translation = "'error." + val["field"] + "'";
        errorArray[val["field"]] = translation;
      }

      this.$store.commit(
        "user/" + types.SET_NOTICE,
        JSON.parse(JSON.stringify(errors))
      );

      return;
    },
    changeStep(index) {
      var self = this;
      var wizard = self.$parent.$parent.$refs.wizard;
      wizard.changeTab(4, index);
    },
    boolText(value) {
      switch (value) {
        case "1":
        case true:
          return this.$i18n.t("option.yes");
          break;
        case "0":
        case false:
          return this.$i18n.t("option.no");
          break;
        case "unknown":
          return this.$i18n.t("radio.notwilling");
          break;
        default:
          return "-";
          break;
      }
    },
    changeCountryList(locale) {
      switch (locale) {
        case "en":
          this.countries = CountriesEN;
          break;
        default:
          this.countries = CountriesNL;
          break;
      }
    },
    getCountryBySlug(slug) {
      let origin = this.countries.find((o) => o.slug === slug);

      if (origin) {
        return origin.name;
      }

      return slug;
    },
  },
  computed: {
    ...mapState("user", [
      "swabCode",
      "general",
      "medical",
      "personal",
      "final",
      "meta",
      "stepState",
      "notice",
    ]),
    ...mapState("app", ["locale", "portal", "loadingStates"]),
    issuersNL: function () {
      return this.portal.issuers.filter(function (issuer) {
        return issuer.country == "Nederland";
      });
    },
    issuers() {
      return this.portal.issuers
        .map(function (elem) {
          return elem.issuerId;
        })
        .join(",");
    },
    submitButton() {
      return this.general.payingUser
        ? this.$i18n.t("button.payingSubmit")
        : this.$i18n.t("button.submit");
    },
    birthday() {
      return (
        this.general.day + "-" + this.general.month + "-" + this.general.year
      );
    },
    postalcode() {
      return this.personal.zipcodeNumerals + " " + this.personal.zipcodeLetters;
    },
    address() {
      return this.personal.street + " " + this.personal.streetNumber;
    },
    gender() {
      switch (this.personal.gender) {
        case "M":
          return this.$i18n.t("option.male");
          break;
        case "F":
          return this.$i18n.t("option.female");
          break;
        default:
          return "-";
          break;
      }
    },
    otherContinent() {
      switch (this.general.otherContinent) {
        case "true":
          return this.$i18n.t("option.yes");
          break;
        case "false":
          return this.$i18n.t("option.no");
          break;
        case "unknown":
          return this.$i18n.t("option.unknown");
          break;
        default:
          return "-";
          break;
      }
    },
    user() {
      return {
        company: this.meta.company,
        reference: this.general.validEventCode
          ? this.general.eventCode
          : this.meta.reference,
        firstName: this.personal.firstName,
        lastName: this.personal.lastName,
        passportName: this.personal.passportName,
        initialsPassport: this.personal.initialsPassport,
        gender: this.personal.gender,
        birthDate:
          this.general.year + "-" + this.general.month + "-" + this.general.day,
        email: this.personal.email,
        emailConfirmation: this.personal.emailConfirmation,
        phone: this.personal.phone,
        street: this.personal.street,
        streetNumber: this.personal.streetNumber,
        zipcodeNumerals: this.personal.zipcodeNumerals,
        zipcodeLetters: this.personal.zipcodeLetters,
        city: this.personal.city,
        weight: this.general.weight,
        height: this.general.height,
        otherContinent: this.general.otherContinent,
        // Removes the default label when otherContinent isnot set
        originFather:
          this.general.otherContinent == "false" ||
          this.general.otherContinent == "unknown"
            ? ""
            : this.general.originFather,
        originMother:
          this.general.otherContinent == "false" ||
          this.general.otherContinent == "unknown"
            ? ""
            : this.general.originMother,
        originFatherMixed: this.general.originFatherMixed,
        originMotherMixed: this.general.originMotherMixed,
        medications: this.medical.medications,
        medicalAffection: this.medical.medicalAffection,
        bloodPlasmaDonor: this.bloodPlasmaDonorResult,
        sanquinSetConsent: this.sanquinSetConsentResult,
        residentNl: this.general.residentNl,
        approvalSignup: this.approvalSignup,
        acceptConditions: this.acceptConditions,
        conditionsVersion: this.$i18n.t("value.conditionsVersion", "x"),
        paramAgePayment: this.meta.paramAgePayment,
        swabCode: this.swabCode,
        payingUser: this.general.payingUser,
        paymentMethod: "ideal",
        childMethod: this.issuer,
        eventCode: this.general.validEventCode,
        usource: this.$cookies.get("_uc_utm_source")
          ? this.$cookies.get("_uc_utm_source")
          : "",
        umedium: this.$cookies.get("_uc_utm_medium")
          ? this.$cookies.get("_uc_utm_medium")
          : "",
        ucampaign: this.$cookies.get("_uc_utm_campaign")
          ? this.$cookies.get("_uc_utm_campaign")
          : "",
        ucontent: this.$cookies.get("_uc_utm_content")
          ? this.$cookies.get("_uc_utm_content")
          : "",
        uterm: this.$cookies.get("_uc_utm_term")
          ? this.$cookies.get("_uc_utm_term")
          : "",
        ireferrer: this.$cookies.get("_uc_referrer")
          ? this.$cookies.get("_uc_referrer")
          : "",
        lreferrer: this.$cookies.get("_uc_last_referrer")
          ? this.$cookies.get("_uc_last_referrer")
          : "",
        ilanding: this.$cookies.get("_uc_initial_landing_page")
          ? this.$cookies.get("_uc_initial_landing_page")
          : "",
        visits: this.$cookies.get("_uc_visits")
          ? this.$cookies.get("_uc_visits")
          : "",
      };
    },
    issuerNotDefault() {
      return this.issuer && this.issuer != this.$i18n.t("ideal.selectIssuer")
        ? true
        : false;
    },
    originFather() {
      if (this.general.originFather == "mixed") {
        return this.general.originFatherMixed;
      }

      // grab the official name of the selected country
      return this.getCountryBySlug(this.general.originFather);
    },
    originMother() {
      if (this.general.originMother == "mixed") {
        return this.general.originMotherMixed;
      }

      // grab the official name of the selected country
      return this.getCountryBySlug(this.general.originMother);
    },
    // requiredIfDonor() {
    //   return (this.bloodPlasmaDonor == 1) ? {required: true, numeric: true, max: 7} : '';
    // },
  },
};
</script>
