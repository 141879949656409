<template>
  <div id="cookiebar" v-if="!cookieAvailable">
    <div class="inner">
      <div class="text">
        <h3>Cookies</h3>
        <p v-html="$t('label.cookie')"></p>
      </div>
      <div class="btns">
        <button
          id="no"
          class="cookiebtn cookiebtn__secondary"
          v-on:click="declineCookie"
        >
          {{ $t("label.cookieDisallowed") }}
        </button>
        <button
          id="ok"
          class="cookiebtn cookiebtn__primary"
          v-on:click="acceptCookie"
        >
          {{ $t("label.cookieAllowed") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import configService from "../../services/config-service";

export default {
  name: "app-cookie",
  data() {
    return {
      cookieAvailable: false,
    };
  },
  created() {
    // If we have a cookie then dont show the cookiebar
    if (this.$cookies.get("cookiebar")) {
      this.cookieAvailable = true;
      // document.body.classList.remove("no-scroll");
    }
    if (this.$cookies.get("cookiebar") === null) {
      // document.body.classList.add("no-scroll");
    }
  },
  methods: {
    acceptCookie(event) {
      event.preventDefault();
      this.cookieAvailable = true;
      // Set a cookie if not set already with cookies allowed
      this.$cookies.set(
        "cookiebar",
        "CookieAllowed",
        "1y",
        "/",
        process.env.VUE_APP_COOKIE_DOMAIN,
        true,
        "none"
      );
      // Enable GA and GTM tags
      this.$gtm.enable(process.env.VUE_APP_ENV === "production");
      location.reload();
    },
    declineCookie(event) {
      event.preventDefault();
      this.cookieAvailable = true;
      // Set a cookie if not set already with cookies allowed
      this.$cookies.set(
        "cookiebar",
        "CookieDisallowed",
        "1y",
        "/",
        process.env.VUE_APP_COOKIE_DOMAIN,
        true,
        "none"
      );
      location.reload();
    },
  },
};
</script>
