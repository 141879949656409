<template>
    <div>
        <div class="questions__item question" :class="questionClass" :id="affectedLabel">
            <div>
                <h4 class="questions__title" v-on:click="$emit('toggle')"><span class="counter"></span> {{ title }}</h4>
                <div class="questions__content">
                    <div class="questions__content__body" v-html="body"></div>

                    <div class="input_wrap radio-group radio-group-button">
                      <input type="radio" :name="affectedLabel" :id="affectedLabelNo" :value="false" class="required" v-model="affected" :data-progress="affectedLabel" @change="$parent.$parent.$parent.$parent.triggerGaEvent('Medisch', 'Vraag ' + id + ': ' + title, 'Nee')">
                      <label :for="affectedLabelNo" data-test="no">{{ $t('option.no') }}</label>
                    </div>
                    <div class="input_wrap radio-group radio-group-button">
                      <input type="radio" :name="affectedLabel" :id="affectedLabelYes" :value="true" class="required" v-model="affected" @change="$parent.$parent.$parent.$parent.triggerGaEvent('Medisch', 'Vraag ' + id + ': ' + title, 'Ja')">
                      <label :for="affectedLabelYes" data-test="yes">{{ $t('option.yes') }}</label>
                    </div>
                    <div class="error-message" v-show="affected">
                        <span class="attention">
                            <span class="ion ion-android-alert"></span>
                            <span v-html="$t('medical.errorQuestion')"></span>
                        </span>
                    </div>
                </div>
                <span class="state state-true">{{ $t('option.no') }}</span>
                <span class="state state-false">{{ $t('option.yes') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
		name: 'medical-question',
        data() {
            return {
                affected: this.affectedProp // default state not true or false
            }
        },
        props: ['question', 'id', 'selected', 'affectedProp'],
        watch: {
            affected(value) {
                this.$emit('affected', value);
            }
        },
        computed: {
            questionClass() {
                return {
                    'is-closed': !((this.id == this.selected) ? true : false),
                    'state-true': (this.affected === false),
                    'state-false': this.affected,
                }
            },
            affectedLabel() {
                return 'affected' + this.id;
            },
            affectedLabelYes() {
                return 'affectedYes' + this.id;
            },
            affectedLabelNo() {
                return 'affectednNo' + this.id;
            },
            body() {
                return ((this.locale == 'nl') ? this.question.body_nl : this.question.body_en);
            },
            title() {
                return ((this.locale == 'nl') ? this.question.title_nl : this.question.title_en);
            },
            ...mapState('app', [
                'locale',
            ]),
        }
    }
</script>
