import {HTTP} from './http-common'

export default {
  init (locale) {
    return HTTP.get('portal/afm/' + locale).then((response) => {
      return response.data.data
    }).catch((error) => {
      return Promise.reject(error.response.data)
    })
  },
  fetchTranslations (locale, i18n) {
    return HTTP.get('translations/afm/' + locale).then((response) => {
      return response.data.data
    }).catch((error) => {
      return Promise.reject(error.response.data)
    })
  }
}
