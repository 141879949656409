<template>
	<div class="main">
		<div class="container">
			<div class="row">
				<div class="col-md-12">

					<div class="wrapper">
						<div class="wrapper-top">
							<div class="app-header app-header-centered app-header-margin">
								<div class="row">
									<div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
										<h1 class="heading heading-bold">{{ $t('title.barcode') }}</h1>
									</div>
								</div>
							</div>
						</div>
						<div class="wrapper-center">
							<div class="row">
								<Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
									<fieldset class="input_style">
										<!-- add class: show-error-message --><!-- add class: error -->
										<div class="form-group code-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
											<div class="row">
												<div class="col-md-12">
													<label for="code">{{ $t('label.barcode') }}
													</label>
													<Field type="text" name="swabcode" id="swabcode" data-test="swabcode" class="form-control required" v-model="swabcode"  />
													<span class="error" v-show="errors.swabcode">{{ $t('error.required') }}</span>
													<div class="error-message" v-show="errors.codeValid">
														<span class="attention">
															<span class="ion ion-android-alert"></span>
															{{ $t('error.barcode') }}
														</span>
													</div>
													<input type="submit" class="submit btn green" :value="$t('button.barcode')" />
													<div class="notes">
														<p>
															{{ $t('helptext.barcode') }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</fieldset>
								</Form>
							</div>
						</div>
						<div class="wrapper-bottom">
							<div class="app-footer app-footer-margin">
								<div class="row">
									<div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
										<p>
											{{ $t('text.barcode_footer') }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import VeeValidate from 'vee-validate';
	import {mapState} from 'vuex';
	import * as types from '../store/mutation-types';

	import { Form, Field } from 'vee-validate';
  import * as yup from 'yup';

	export default {
		name: 'barcode',
		data() {
				return {
						swabcode: '',
				}
		},
		components: {
      Form,
      Field,
    },
				setup() {

					const schema = yup.object({
						swabcode : yup.string().required().min(7).max(7).test('validSwabcode', 'Ongeldige Swabcode', 
            function(value) {
              var number = value;
              var sumTable = [
                  [0,1,2,3,4,5,6,7,8,9],
                  [0,2,4,6,8,1,3,5,7,9]
              ];
              var sum = 0;
              var flip = 0;

              for (var i = number.length - 1; i >= 0; i--) {
                sum += sumTable[flip++ & 0x1][number[i]];
              }

              var validsum = sum % 10 === 0;
              if(validsum){
                  return true;
              }else{
                  return false;
              }
            }),
					});
					return {
							schema
					};
				},
        methods:{
					onSubmit(){
						this.$store.dispatch('user/checkSwabcode', this.$data).then((response) => {
							if (response) {
								this.$store.commit('user/'+types.SET_SWABCODE, this.swabcode);
								this.$router.push('/algemeen');
							}
							return false;
						}).catch((error) => {
						this.toErrorArray(error.errors);
								return false;
						});
					},
					toErrorArray: function(errors){
						var errorArray = [];
						var val;
						for (val of errors) {
							errorArray[0] = {
								field: val['field'],
								message: val['message'],
							};
						}
						this.$store.commit('user/'+types.SET_NOTICE,JSON.parse(JSON.stringify(errorArray)));
						return;
					},
        },
        computed: {
            validcode() {
                return this.code;
            },
        },
	}
</script>
