<template>
	<div class="alert alert-info error animated bounceIn" v-bind:class="{active: isActive}">
		<ul>
            <li v-for="error in notice">
				
                <span v-html="error.field +' : '+ error.message"></span>
            </li>
        </ul>
		<div class="alert-close" v-on:click="close">
			<span class="fa fa-times"></span>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import * as types from '../../store/mutation-types';

  export default {
  	name: 'app-alert',
		computed: {
            ...mapState ('user', [
			    'notice',
			]),
		    isActive() {
			    return (this.notice) ? true : false;
			}
		},
		methods: {
			close(event){
				this.$store.commit('user/'+types.RESET_NOTICE);
			}
		}
  }
</script>
