import { createRouter, createWebHistory } from 'vue-router'
import Vue from 'vue';
import store from './store'
import * as types from './store/mutation-types'

// routes components
// SignupForm
import Home from './components/Home.vue'
import Family from './components/Family.vue'
import Kor from './components/Kor.vue'
import Brian from './components/Brian.vue'
import Signup from './components/Signup.vue'
import SignupGeneral from './components/signup/pages/SignupGeneral.vue'
import SignupMedical from './components/signup/pages/SignupMedical.vue'
import SignupPersonal from './components/signup/pages/SignupPersonal.vue'
import SignupFinal from './components/signup/pages/SignupFinal.vue'
import SignupThanks from './components/signup/pages/SignupThanks.vue'
import SignupError from './components/signup/pages/SignupError.vue'
// Eventform
import EventWrap from './components/Event.vue'
import EventForm from './components/event/EventForm.vue'
import EventThanks from './components/event/EventThanks.vue'
// Barcode
import Barcode from './components/Barcode.vue'
// Notification
import Later from './components/Later.vue'
import LaterForm from './components/later/LaterForm.vue'
import LaterThanks from './components/later/LaterThanks.vue'
// Bijna 18
import Bijna18 from './components/Bijna18.vue'
import Bijna18Form from './components/bijna18/Bijna18Form.vue'
import Bijna18Thanks from './components/bijna18/Bijna18Thanks.vue'


const routes = [
  {
    path: '/start',
    name: 'home',
    component: Home,
    beforeEnter: (to, from, next) => {
      store.commit('user/' + types.SET_FIRSTVISIT, false)
      next()
    }
  },
  {
    path: '/familie',
    name: 'family',
    component: Family,
    beforeEnter: (to, from, next) => {
      store.commit('user/' + types.SET_FIRSTVISIT, false)
      next()
    }
  },
  {
    path: '/kor',
    name: 'kor',
    component: Kor,
    beforeEnter: (to, from, next) => {
      store.commit('user/' + types.SET_FIRSTVISIT, false)
      next()
    }
  },
  {
    path: '/brian',
    name: 'brian',
    component: Brian,
    beforeEnter: (to, from, next) => {
      store.commit('user/' + types.SET_FIRSTVISIT, false)
      next()
    }
  },
  {
    path: '/',
    name: 'signupform',
    component: Signup,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
        beforeEnter: (to, from, next) => {
          if (to.query.hash) {
            store.dispatch('user/getEventDonor', to.query.hash).then((response) => {
              if (typeof response.errors !== 'undefined' && response.data.errors) {
                store.commit('user/' + types.SET_NOTICE, response.data)
              } else {
                store.commit('user/' + types.GET_EVENT, response.data)
                store.commit('user/' + types.SET_FIRSTVISIT, false)
                router.push('/start')
              }
            }).catch((error) => {
              console.log(error)
            })
          } else if (to.query.company || to.query.reference) {
            store.commit('user/' + types.SET_REFERENCE, {
              company: ((to.query.company) ? to.query.company : ''),
              reference: ((to.query.reference) ? to.query.reference : '')
            })
            store.commit('user/' + types.SET_FIRSTVISIT, false)
            router.push('/start')
          } else if (to.query.eventcode) {
            store.dispatch('user/getEventCode', to.query.eventcode).then((response) => {
              store.commit('user/' + types.SET_EVENTCODE, response.code)
              store.commit('user/' + types.SET_FIRSTVISIT, false)
              router.push('/algemeen')
            }).catch((error) => {
              router.push('/start')
            })
          } else if (store.state.user.firstVisit == true) {
            router.push({ name: 'home', query: to.query})
            store.commit('user/' + types.SET_FIRSTVISIT, false)
          } else if (store.state.user.firstVisit == false) {
            router.push({ name: 'form-general'})
          } else {
            next()
          }
        },
      },
      {
        path: 'algemeen',
        name: 'form-general',
        component: SignupGeneral,
        beforeEnter: (to, from, next) => {
          store.commit('user/' + types.SET_STEPSTATE, 1)
          next()
        },
      },
      {
        path: 'medisch',
        name: 'form-medical',
        component: SignupMedical,
        beforeEnter: (to, from, next) => {
          store.commit('user/' + types.SET_STEPSTATE, 2)
          if (!store.state.user.general.residentNl) next({ name: 'form-general' })
          next()
        },
      },
      {
        path: 'persoonlijk',
        name: 'form-personal',
        component: SignupPersonal,
        beforeEnter: (to, from, next) => {
          store.commit('user/' + types.SET_STEPSTATE, 3)
          if (store.state.user.medical.medicalAffection === true) next({ name: 'form-medical' })
          next()
        },
      },
      {
        path: 'definitief',
        name: 'form-final',
        component: SignupFinal,
        beforeEnter: (to, from, next) => {
          store.commit('user/' + types.SET_STEPSTATE, 4)
          if (!store.state.user.personal.email) next({ name: 'form-personal' })
          next()
        },
      }
    ]
  },
  {
    path: '/bedankt',
    name: 'SignupThanks',
    component: SignupThanks
  },
  {
    path: '/error',
    name: 'SignupError',
    component: SignupError
  },
  {
    path: '/event',
    redirect: { name: 'form-general' }
  //   component: EventWrap,
  //   children: [
  //     {
  //       path: '',
  //       name: 'event-form',
  //       component: EventForm
  //     },
  //     {
  //       path: 'bedankt',
  //       name: 'form-thanks',
  //       component: EventThanks
  //     }
  //   ]
  },
  {
    path: '/barcode',
    name: "barcode-form",
    component: Barcode
    // redirect: { name: 'form-general' }
  },
    // {
    //     path: '/later-aanmelden',
    //     component: Later,
    //     children: [
    //         {
    //             path: '',
    //             name: 'later-form',
    //             component: LaterForm
    //         },
    //         {
    //             path: 'bedankt',
    //             name: 'later-thanks',
    //             component: LaterThanks
    //         },
    //     ],
    // },
  {
    path: '/nog-geen-18',
    component: Bijna18,
    children: [
      {
        path: '',
        name: 'bijna-18-form',
        component: Bijna18Form
      },
      {
        path: 'bedankt',
        name: 'bijna-18-thanks',
        component: Bijna18Thanks
      }
    ]
  },
  {
    path: '/en',
    beforeEnter(to, from, next) {
      store.commit('app/' + types.SET_LOCALE, 'en');
      window.location.href = "/?lang=en";
    }
  },
  {
    path: '/*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

function hasQueryParams (route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
    // check if localisation/init query isset if so set new locale, will fill if api doens't recognize
  if (to.query.lang) {
    store.commit('app/' + types.SET_LOCALE, to.query.lang)
    next()
  }
  next()
})

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default router
