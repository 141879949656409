import portalService from '../../services/portal-service'
import * as types from '../mutation-types'

// Set default language based on url query.
var defaultLanguage = 'nl';
if (window.location.search.indexOf("lang=en") > -1) {
  defaultLanguage = 'en';
}

export default {
  namespaced: true,
  state: {
    locale: defaultLanguage,
    notice: false,
    stepState: 0,
    portal: {
      issuers: [],
      portalKey: null,
      portalName: null,
      locale: null,
      locales: {},
      translations: {}
    },
    loadingStates: {
      user: false,
      app: false,
      translations: false
    }
  	},
  	mutations: {
    [types.LOADING_USER] (state) {
      state.loadingStates.user = true
    },
    [types.USER_LOADED] (state) {
      state.loadingStates.user = false
    },
    [types.LOADING_APP] (state) {
      state.loadingStates.app = true
    },
    [types.APP_LOADED] (state) {
      state.loadingStates.app = false
    },
    [types.LOADING_TRANSLATIONS] (state) {
      state.loadingStates.translations = true
    },
    [types.TRANSLATIONS_LOADED] (state) {
      state.loadingStates.translations = false
    },
    [types.SET_LOCALE] (state, locale) {
      state.locale = locale
      document.getElementsByTagName('html')[0].setAttribute('lang', locale)
    },
    [types.SET_STEPSTATE] (state, stepState) {
      state.stepState = stepState
    },
    [types.SET_PORTAL] (state, portal) {
      state.portal = portal
    },
    [types.SET_TRANSLATIONS] (state, response) {
      state.portal.translations = response.translations
    },
    [types.SET_NOTICE] (state, notice) {
      state.notice = notice
    },
    [types.RESET_NOTICE] (state) {
      state.notice = false
    },
    [types.RESET_APP] (state) {
      state.loadingStates = {
        user: false,
        app: false,
        translations: false
      }
    }
  },
  	actions: {
    	init ({state, commit, rootState}) {
      commit(types.LOADING_APP)
      return portalService.init(state.locale).then((response) => {
		commit(types.SET_PORTAL, response)
        commit(types.APP_LOADED)
        return Promise.resolve(response)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },
    fetchTranslations ({state, commit, rootState}, locale) {
      commit(types.LOADING_TRANSLATIONS)
      		return portalService.fetchTranslations(locale).then((response) => {
        commit(types.SET_TRANSLATIONS, {locale: locale, translations: response.translations})
        commit(types.SET_LOCALE, locale)
        commit(types.TRANSLATIONS_LOADED)
        return Promise.resolve(response)
      }).catch((error) => {
        return Promise.reject(error)
      })
    }
  }
}
