<template>
	<div>
		<p v-html="$t('bijna18.intro')"></p>
		<Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
			<fieldset class="input_style row">
				<div class="col-md-6">
					<div class="form-group">
						<label for="firstName" >{{ $t('bijna18.firstName') }}
						</label>
						<Field type="text" name="firstName" id="firstName" data-test="firstName" class="form-control required" autocomplete="no" v-model="firstName" data-progress="firstName" />
						<span class="error" v-show="errors.firstName">{{ $t('error.required') }}</span>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label for="lastName">{{ $t('label.lastName') }}</label>
						<Field type="text" name="lastName" id="lastName" data-test="lastName" class="form-control required" autocomplete="no" v-model="lastName" data-progress="lastName" />
						<span class="error" v-show="errors.lastName">{{ $t('error.required') }}</span>
					</div>
				</div>
				<div class="col-md-12">
					<div class="form-group">
						<label for="email">E-maildres</label>
						<Field type="text" name="email" id="email" data-test="email" class="form-control required email" v-model="email" />

						<div class="error-message" v-show="errors.email">
							<span class="attention">
								<span class="ion ion-android-alert"></span>
								{{ $t('error.email') }}
							</span>
						</div>
					</div>
				</div>
				<div class="col-md-12">
					<div class="form-group select-group">
						<div class="row">
							<div class="col-md-12">
								<span class="label">{{ $t('label.birthDate') }}</span>
							</div>
							<div class="col-md-12">
								<div class="row error-scroll-pos">
									<div class="input_wrap col-md-3">
										<label for="day">
											<span class="fa fa-calendar-o"></span>
											<span class="placeholder" :class="{'hidden': day}">{{ $t('placeholder.day') }}</span>
										</label>
										<Field as="select" autocomplete="off" class="form-control select-day required" name="day" id="day" data-msg="Verplicht" v-model="day">
											<option v-for="dayNr in days" :value="dayNr">{{ dayNr }}</option>
										</Field>
										<span class="error" v-show="errors.day">{{ $t('error.required') }}</span>
									</div>
									<div class="input_wrap col-md-6">
										<label for="month">
											<span class="placeholder" :class="{'hidden': month}">{{ $t('placeholder.month') }}</span>
										</label>
										<Field as="select" autocomplete="off" name="month" id="month" class="form-control select-month required" v-model="month">
											<option value="01">{{ $t('option.jan') }}</option>
											<option value="02">{{ $t('option.feb') }}</option>
											<option value="03">{{ $t('option.mar') }}</option>
											<option value="04">{{ $t('option.apr') }}</option>
											<option value="05">{{ $t('option.may') }}</option>
											<option value="06">{{ $t('option.jun') }}</option>
											<option value="07">{{ $t('option.jul') }}</option>
											<option value="08">{{ $t('option.aug') }}</option>
											<option value="09">{{ $t('option.sep') }}</option>
											<option value="10">{{ $t('option.oct') }}</option>
											<option value="11">{{ $t('option.nov') }}</option>
											<option value="12">{{ $t('option.dec') }}</option>
										</Field>
										<span class="error" v-show="errors.month">{{ $t('error.required') }}</span>
									</div>
									<div class="input_wrap col-md-3">
										<label for="year">
											<span class="placeholder" :class="{'hidden': year}">{{ $t('placeholder.year') }}</span>
										</label>
										<Field as="select" autocomplete="off" class="form-control select-year required" name="year" id="year" v-model="year" data-vv-validate-on="none" data-progress="birthdate">
											<option v-for="yearNr in years" :value="yearNr">{{ yearNr }}</option>
										</Field>
										<span class="error" v-show="errors.year">{{ $t('error.required') }}</span>
									</div>
								</div>
								<Field name="birthdate" v-model="birthdate" class='hidden' data-vv-delay="1000" />
								<div class="col-md-12 error-message" v-show="errors.birthdate">
									<span class="attention">
										<span class="ion ion-android-alert"></span>
										<p v-html="$t('error.bijna18age')"></p>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-12">
					<div class="form-group checkbox-group">
						<div class="row">
								<div class="col-md-12">
									<span class="label">{{ $t("bijna18.dataTitle") }}</span>
								</div>
								<div class="input_wrap col-md-12">
								<Field type="checkbox" name="useData" id="useData" value="yes-use-data" class="required" v-model="useData" data-progress="useData" />
								<label for="useData" data-test="useData">
									{{ $t("bijna18.dataText") }}
									</label>
								</div>
								<span class="error" v-show="errors.useData">{{ $t("error.required") }}</span>
						</div>
						</div>
				</div>
				<div class="col-md-12">
					<input type="submit" class="submit btn orange" :value="$t('later.button')"
					:disabled="loading" />
				</div>
			</fieldset>
		</Form>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {mapState} from 'vuex';
	import * as types from '../../store/mutation-types';

	import { Form, Field } from 'vee-validate';
  import * as yup from 'yup';

	export default {
		name: 'bijna18',
		components: {
			Form,
			Field,
		},
		data() {
			return {
				firstName: "",
				lastName: "",
				email: "",
				day: "",
				month: "",
				year: "",
				useData: null,
				submitted: false,
				loading: false,
			}
		},
		setup() {        

			let minAgeDate = new Date(); 
			minAgeDate.setFullYear(minAgeDate.getFullYear() -15);

			let maxAgeDate = new Date(); 
      maxAgeDate.setFullYear(maxAgeDate.getFullYear() -18);

			const schema = yup.object({
				firstName: yup.string().required(),
				lastName: yup.string().required(),
				day: yup.number().required(),
				month: yup.number().required(),
				birthdate: yup.string().when(['day','month', 'year'], (day,month,year) => {
              if (day && month && year) {
                return yup.string().test(
                  'is-correct-age',
                  'Incorrect age',
                  function () {
                    var age = new Date(year,month,day).getTime();
                    var maxAgeTime = maxAgeDate.getTime();
                    var minAgeTime = minAgeDate.getTime();

                    return age <= minAgeTime && age >= maxAgeTime;
                  },
                );
                  
              }
            }),
				year: yup.number().required(),
				useData: yup.mixed().required()
			});
			return {
				schema
			};
		},
		methods: {
			onSubmit() {
				this.bijna18Validation();
			},
			bijna18Validation: function(){
				// lets get rid of components variables
				const user = {
					firstName: this.firstName,
					lastName: this.lastName,
					email: this.email,
					birthDate: this.year + "-" + this.month + "-" + this.day,
				};
				this.$store.dispatch('user/saveYoungDonor', user).then((response) => {
					if (response.data) {
						this.submitted = true;
						this.$router.push("/nog-geen-18/bedankt");
					}
					this.loading = false;
					return false;
				}).catch((error) => {
					// errors from backend show on top
					this.$store.commit('app/'+types.USER_LOADED);
					this.toErrorArray(error.errors);
					resolve(result);
					this.loading = false;
					return false;
				});
			},
			toErrorArray: function(errors){
				var errorArray = [];
				var val;
				for (val of errors) {
					var translation = "'error."+val['field']+"'";
					errorArray[val['field']] = translation;
				}
				this.$store.commit('user/'+types.SET_NOTICE,JSON.parse(JSON.stringify(errors)));

				return;
			},
			validateAge(birthday, minage, maxage, separator) {
				if (!parseInt(minage) || !parseInt(maxage)) {
					return false;
				}
				var split = birthday.split(separator);
				var dateDay = split[0];
				var dateMonth = split[1];
				var dateFullYear = split[2];
				var mydate = new Date();

				mydate.setFullYear(dateFullYear, dateMonth-1, dateDay);

				var currdate = new Date();
				var minDate = new Date();
				var maxDate = new Date();
				minDate.setFullYear(mydate.getFullYear() + minage, dateMonth-1, dateDay -1);
				maxDate.setFullYear(mydate.getFullYear() + maxage, dateMonth-1, dateDay);
				if((currdate - minDate) > 0 && (currdate - maxDate) < 0){
					return true;
				}
				return false;
			},
		},
		computed: {
			...mapState ('app', [
				'locale'
			]),
			days() {
				let days = [];
				for (let index = 1; index <= 31; index++) {
					days.push(index);
				}
				return days;
			},
			years: function() {
				const currYear = new Date().getFullYear();
				const min = currYear - 12;
				const max = currYear - 30;
				let years = [];
				for (let index = min; index >= max; index--) {
				years.push(index);
				}
				return years;
			},
			birthdate() {
				return this.day + '/' + this.month  + '/' + this.year;
			},
		},
	}
</script>
