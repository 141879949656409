// The following line loads the standalone build of Vue instead of the runtime-only build,
// so you don't have to do: import Vue from 'vue/dist/vue'
// This is done with the browser options. For the config, see package.json
const { createApp } = require('vue');
import App from './components/App.vue'
import store from './store'
import router from './router'
import VueGtm from 'vue-gtm'
import VueCookies from 'vue3-cookies'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import configService from './services/config-service'
import { createI18n } from 'vue-i18n'

// import gtag from "vue-gtag-next";

// sentry logging
// only on production
// if (process.env.VUE_APP_ENV == 'production' || process.env.VUE_APP_ENV == 'staging') {
//   Raven
//         .config(configService.get('dsn'))
//         .addPlugin(RavenVue, Vue)
//         .install()
// }

// // extend veevalidate with custom rules
// VeeValidate.Validator.extend('valid_nl_phone', {
//   getMessage: field => 'The phonenumber must be valid)',
//   validate: value => {
//     let pn = new PhoneNumber(value, 'NL')
//     return pn.isValid()
//   }
// })
// VeeValidate.Validator.extend('nodefault', {
//   getMessage: field => 'Default value is not valid',
//   validate: (value, args) => {
//     if (value == args[0]) {
//       return false
//     }
//     return true
//   }
// })

var userLang = localStorage.getItem('selectedLang') || navigator.language || navigator.userLanguage;
const i18n = createI18n({
  locale: (userLang.substring(0, 2) == 'en') ? 'en' : 'nl',
  fallbackLocale: 'nl',
  fallbackWarn: false,
  missingWarn: false
})

const app = createApp(App)
.use(i18n)
.use(store)
.use(router)
// .use(createMetaManager())


app.use(VueCookies, {
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None"
});

const vm = app.mount('#app')

// Init Google GTM
app.use(VueGtm, {
  id: 'GTM-K9B6PQN',
  enabled: (process.env.VUE_APP_ENV === 'production'),
  debug: false,
  vueRouter: router
})


if (typeof window.getComputedStyle(document.body).backgroundBlendMode !== 'undefined') {
  document.documentElement.className += ' no-background-blend-mode'
}
