// app store
export const LOADING_USER = 'LOADING_USER'
export const USER_LOADED = 'USER_LOADED'
export const LOADING_APP = 'LOADING_APP'
export const APP_LOADED = 'APP_LOADED'
export const LOADING_TRANSLATIONS = 'LOADING_TRANSLATIONS'
export const TRANSLATIONS_LOADED = 'TRANSLATIONS_LOADED'
export const SET_LOCALE = 'SET_LOCALE'
export const SET_PORTAL = 'SET_PORTAL'
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS'
export const RESET_APP = 'RESET_APP'

// user store
export const SET_NOTIFIER = 'SET_NOTIFIER'
export const SET_STEPSTATE = 'SET_STEPSTATE'
export const SET_PROGRESS = 'SET_PROGRESS'
export const SET_SWABCODE = 'SET_SWABCODE'
export const SET_EVENTCODE = 'SET_EVENTCODE'
export const SET_FIRSTVISIT = 'SET_FIRSTVISIT'
export const SET_GENERAL = 'SET_GENERAL'
export const SET_MEDICAL = 'SET_MEDICAL'
export const SET_PERSONAL = 'SET_PERSONAL'
export const SET_FINAL = 'SET_FINAL'
export const SET_EVENT = 'SET_EVENT'
export const SET_NOTICE = 'SET_NOTICE'
export const RESET_NOTICE = 'RESET_NOTICE'
export const RESET_USER = 'RESET_USER'
export const RESET_EVENT = 'RESET_EVENT'
export const SET_REFERENCE = 'SET_REFERENCE'
export const GET_EVENT = 'GET_EVENT'
//
