<template>
	<div class="main">
		<div class="container">
			<div class="row">
				<div class="col-md-8 col-md-offset-2">
					
					<div class="wrapper wrapper-small">
						<div class="wrapper-top">
							<div class="app-header">
								<h1 class="heading">{{ $t('home.title') }}</h1>
							</div>
						</div>
						<div class="wrapper-center">
							<div class="app-content app-content-centered">
								<p><strong>{{ $t('home.intro') }}</strong></p>
								<p>
									{{ $t('home.text') }}
								</p>
								<router-link to="algemeen" @click="triggerGaEvent('Start', 'Aanmelden als stamceldonor', 'Ja')"><span class="btn orange">{{ $t('button.signup') }}</span></router-link>
								<!-- <router-link to="/later-aanmelden"><span class="btn grey">{{ $t('button.laterStart') }}</span></router-link> -->
							</div>
						</div>
						<div class="wrapper-bottom">
							<div class="app-footer app-footer-centered">
								<span v-html="$t('footer.link')" @click="triggerGaEvent('Start', 'Nog niet zeker? Lees hier meer informatie', 'Ja')"></span>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import vue from 'vue';

	export default {
		name: 'home',
		metaInfo() {
				return {
						title: Vue.i18n.translate("meta-title.home"),
				}
		},
		methods: {
			triggerGaEvent(cat, action, label) {
				if (process.env.VUE_APP_ENV == 'production') {
					window.dataLayer.push({
						'event': 'form_interactie',
						'Cat' : cat,
						'Action': action,
						'Label' : label,
					});
				} else{
					console.log('Cat: "' + cat + '" Action: "' + action + '" Label: "' + label + "\"");
				}
			},
		}
	}
</script>
