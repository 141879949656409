<template>
	<div>
		<p><strong>{{ $t("later.title") }}</strong></p>
		<p v-html="$t('later.intro')"></p>

		<fieldset class="input_style">
			<label for="email" class="hidden">E-maildres</label>
			<input type="text" name="email" id="email" data-test="email" class="form-control required email" v-model="email" v-validate="'required|email'" data-vv-validate-on="none" placeholder="e-mailadres">
			<!-- <span class="error" v-show="errors.has('email')">{{ $t('error.required') }}</span> -->

			<div class="error-message" v-show="errors.has('email')">
				<span class="attention">
					<span class="ion ion-android-alert"></span>
					{{ errors.first('email') }}
				</span>
			</div>

			<input type="submit" @click="submitLater()" class="submit btn orange" :value="$t('later.button')" 
			:disabled="loading" />
		</fieldset>
	</div>
</template>

<script>
	import Vue from 'vue';
	import VeeValidate from 'vee-validate';
	import {mapState} from 'vuex';
	import * as types from '../../store/mutation-types';

	export default {
        name: 'later',
		data() {
            return {
				email: "",
				submitted: false,
				loading: false,
            }
		},
		methods: {
			submitLater() {
				this.$store.commit('user/'+types.RESET_NOTICE);
				return new Promise((resolve, reject) => {
					this.$validator.validateAll().then((result) => {
						this.loading = true;
						if (result) {
							var data = {email: this.email, lang: this.locale};
							this.$store.commit('user/'+types.SET_NOTIFIER, data);
                            this.$store.dispatch('user/saveNotifier', data).then((response) => {
								if (response.data) {
									this.submitted = true;
									this.$router.push("/later-aanmelden/bedankt");
								}
								this.loading = false;
                                return false;
							}).catch((error) => {
								this.errors.add({
									field: 'email',
									msg: Vue.i18n.translate('error.duplicateEmail'),
								});
								this.loading = false;
								return false;
                            });
                        } else {
							this.loading = false;
                            resolve(result);
                        }
                    });
                });
			},
		},
		computed: {
           	...mapState ('app', [
				'locale'
			]),	
		},
	}
</script>
