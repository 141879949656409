<template>
	<div class="animated fadeIn">
		<p><strong>{{ $t("later.thanksTitle") }}</strong></p>
		<p>
			{{ $t("later.thanksText") }}
		</p>
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
			<circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
			<polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
		</svg>
	</div>
</template>

<script>
	import Vue from 'vue';
	import VeeValidate from 'vee-validate';
	import {mapState} from 'vuex';
	import * as types from '../../store/mutation-types';

	export default {
        name: 'laterThanks',
		data() {
            return {
				email: "",
				submitted: false,
            }
		},
		created() {
			this.submitted = true;
		},
		computed: {
           	...mapState ('app', [
				'locale'
			]),	
		},
	}
</script>
