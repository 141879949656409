<template>
  <div class="page-content">
    <template v-if="!loadingStates.app">
      <metainfo>
        <template v-slot:title="{ content }">{{ content }} - Yay!</template>
      </metainfo>
      <app-header></app-header>
      <router-view></router-view>
      <app-cookie></app-cookie>
    </template>
  </div>
</template>

<script>
import AppHeader from "./layout/Header.vue";
import AppCookie from "./app/AppCookie.vue";
import * as types from "../store/mutation-types";
import { mapState } from "vuex";
import $ from "jquery";

import VueMeta from "vue-meta";

export default {
  name: "app",
  components: {
    AppHeader,
    AppCookie,
  },
  metaInfo() {
    return {
      title: "Test Title",
    };
  },
  computed: {
    ...mapState("app", ["loadingStates", "locale", "portal"]),
  },
  created() {
    // setup default locale if not already set by router trough init call ?locale=en

    document.getElementsByTagName("html")[0].setAttribute("lang", this.locale);

    // Setup GA if we have cookies that allow this

    // init the app/portal settings
    var self = this;
    this.$store
      .dispatch("app/init")
      .then((response) => {
        this.$i18n.setLocaleMessage("nl", response.translations);
        this.$i18n.setLocaleMessage("en", response.translations);
        if (this.$cookies.get("cookiebar") == "CookieAllowed") {
          this.$gtm.enable(true);
        }
        if (this.$cookies.get("cookiebar") == "CookieDisallowed") {
          this.$gtm.enable(false);
        }
        // nothings it works, moving on..
        $("#errorOnLoad").hide();
      })
      .catch((error) => {
        console.log(error);
        // On some OSX devices on Mobile and or Desktop Safari and Chrome Mobile
        // the page hangs due a random bug SNI 421 Misdirected Request
        // could be something with multiple request from the same domain
        $("#errorOnLoad").show();
        setTimeout(function () {
          self.$store
            .dispatch("app/init")
            .then(() => {
              // worked the second time, temp fix for now...
              $("#errorOnLoad").hide();
            })
            .catch((error) => {
              // still errors never mind..
            });
        }, 4000);
      });

    // reset notice on refresh
    this.$store.commit("app/" + types.RESET_NOTICE);
  },
};
</script>
