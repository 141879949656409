// Es6 promise IE11 fix
import Es6Promise from 'es6-promise'
Es6Promise.polyfill()

import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import createPersistedState from 'vuex-persistedstate'
import configService from '../services/config-service'
import _ from 'lodash'

// Vue.use(Vuex);

const debug = configService.get('env') !== 'production'

const store = new Vuex.Store({
  modules: {
    app,
    user
  },
  strict: debug,
  plugins: [
    createPersistedState({
      key: 'store',
      paths: ['app', 'i18n']
    }),
    createPersistedState({
      key: 'user',
      storage: window.sessionStorage,
      paths: ['user']

    })
  ]
})

export default store

const initialStateCopy = JSON.parse(JSON.stringify(store.state))

// reset state if needed
export function resetState () {
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)))
}
