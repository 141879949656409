import userService from '../../services/user-service'
import * as types from '../mutation-types'

export default {
  namespaced: true,
  state: {
    stepState: 0,
    progress: 0,
    firstVisit: true,
    showEventBlock: true,
    swabCode: null,
    general: {
      residentNl: '',
      day: '',
      month: '',
      year: '',
      weight: '',
      height: '',
      bloodPlasmaDonor: '',
      sanquinSetConsent: '',
      otherContinent: '',
      originFather: '',
      originMother: '',
      originFatherMixed: '',
      originMotherMixed: '',
      payingUser: false,
      eventCode: '',
      validEventCode: false,
      showEventCode: false,

      loadingEventCode: false,
      eventCodeError: false
    },
    medical: {
      medicalAffection: true,
      medications: [],
      allProcessed: false,
    },
    personal: {
      gender: '',
      firstName: '',
      lastName: '',
      passportName: '',
      initialsPassport: '',
      email: '',
      emailConfirmation: '',
      phone: '',
      zipcodeNumerals: '',
      zipcodeLetters: '',
      streetNumber: '',
      street: '',
      city: ''
    },
    final: {
      approvalSignup: '',
      acceptConditions: ''
    },
    notice: false,
    event: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      day: '',
      month: '',
      year: '',
      weight: '',
      height: '',
      healthy: '',
      swabCode: null
    },
    meta: {
      company: '',
      reference: '',
      paramAgePayment: 36
    },
    notify: {
      email: '',
      lang: ''
    },
    almost18: {
      firstName: '',
      lastName: '',
      birthDate: '',
      email: ''
    }
  },
  mutations: {
    [types.SET_STEPSTATE] (state, stepState) {
      state.stepState = stepState
    },
    [types.SET_PROGRESS] (state, progress) {
      state.progress = progress
    },
    [types.SET_NOTIFIER] (state, notify) {
      state.notify.email = notify.email
      state.notify.lang = notify.lang
    },
    [types.SET_FIRSTVISIT] (state, firstVisit) {
      state.firstVisit = firstVisit
    },
    [types.SET_EVENTCODE] (state, eventCode) {
      state.general.eventCode = eventCode
      state.general.validEventCode = true
      state.general.showEventCode = false
      state.showEventBlock = false
    },
    [types.SET_SWABCODE] (state, swabCode) {
      state.swabCode = swabCode
    },
    [types.SET_GENERAL] (state, general) {
      state.general = general
    },
    [types.SET_MEDICAL] (state, medical) {
      state.medical = medical
    },
    [types.SET_PERSONAL] (state, personal) {
      state.personal = personal
    },
    [types.SET_FINAL] (state, final) {
      state.final = final
    },
    [types.SET_EVENT] (state, event) {
      state.event = event
    },
    [types.GET_EVENT] (state, event) {
      state.general.weight = event.weight
      state.general.height = event.height
      state.personal.firstName = event.firstName
      state.personal.lastName = event.lastName
      state.personal.email = event.email
      state.personal.emailConfirmation = event.email
      state.medical.medicalAffection = '0'
      state.swabCode = event.swabCode

            // split bday in seperate fields
      var birthday = event.birthDate.split('-')
      state.general.day = birthday[0]
      state.general.month = birthday[1]
      state.general.year = birthday[2]
    },
    [types.SET_REFERENCE] (state, reference) {
      state.meta.company = reference.company
      state.meta.reference = reference.reference
    },
    [types.SET_NOTICE] (state, notice) {
      state.notice = notice
    },
    [types.RESET_NOTICE] (state) {
      state.notice = false
    },
    [types.RESET_EVENT] (state) {
      state.event = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        day: '',
        month: '',
        year: '',
        weight: '',
        height: '',
        healthy: '',
        swabCode: null
      }
    },
    [types.RESET_USER] (state) {
      state.stepState = 0
      state.firstVisit = true
      state.swabCode = null
      state.general = {
        residentNl: '',
        day: '',
        month: '',
        year: '',
        weight: '',
        height: '',
        bloodPlasmaDonor: '',
        sanquinSetConsent: '',
        otherContinent: '',
        originFather: '',
        originMother: '',
        originFatherMixed: '',
        originMotherMixed: '',
        payingUser: false,
        eventCode: '',
        validEventCode: false,
        showEventCode: false,
        loadingEventCode: false,
        eventCodeError: false
      }
      state.medical = {
        medicalAffection: true,
        medications: []
      }
      state.personal = {
        gender: '',
        firstName: '',
        lastName: '',
        passportName: '',
        initialsPassport: '',
        email: '',
        emailConfirmation: '',
        phone: '',
        zipcodeNumerals: '',
        zipcodeLetters: '',
        streetNumber: '',
        street: '',
        city: ''
      }
      state.final = {
        approvalSignup: '',
        acceptConditions: ''
      }
      state.meta = {
        company: '',
        reference: '',
        paramAgePayment: 36
      }
      state.notice = false
    }
  },
  actions: {
    fetchAddress ({state, commit, rootState}, address) {
      return userService.fetchAddress(address).then((response) => {
        return Promise.resolve(response)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },
    getEventCode ({state, commit, rootState}, eventCode) {
      return userService.getEventCode(eventCode).then((response) => {
        return Promise.resolve(response)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },
    checkSwabcode ({state, commit, rootState}, swabCode) {
      return userService.checkSwabcode(swabCode).then((response) => {
        return Promise.resolve(response)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },
    saveDonor ({state, commit, rootState}, user) {
      return userService.saveDonor(user).then((response) => {
        return Promise.resolve(response.data)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },
    saveNotifier ({state, commit, rootState}, data) {
      return userService.saveNotifier(data).then((response) => {
        return Promise.resolve(response.data)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },
    saveEventDonor ({state, commit, rootState}, user) {
      return userService.saveEventDonor(user).then((response) => {
        return Promise.resolve(response)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },
    getEventDonor ({state, commit, rootState}, hash) {
      return userService.getEventDonor(hash).then((response) => {
        return Promise.resolve(response)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },
    setPayment ({state, commit, rootState}, paymentMethod) {
      return userService.setPayment(paymentMethod).then((response) => {
        return Promise.resolve(response)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },
    paymentStatus ({state, commit, rootState}, transaction) {
      return userService.paymentStatus(transaction).then((response) => {
        return Promise.resolve(response)
      }).catch((error) => {
        return Promise.reject(error)
      })
    },
    saveYoungDonor ({state, commit, rootState}, user) {
      return userService.saveYoungDonor(user).then((response) => {
        return Promise.resolve(response)
      }).catch((error) => {
        return Promise.reject(error)
      })
    }
  }
}
