<template>
  <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }"  v-on:change="setProgress()" id="personal" class="validate">
    <div class="wrapper-center" >
        <h2>{{ $t("nav.personal")}}</h2>
        <div class="row">
            <fieldset class="input_style" >
              <!-- add class: error -->
    	      <div class="form-group radio-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 error">
    	          <div class="row error-scroll-pos">

                    <div class="col-md-12">
                        <span class="label">{{ $t('label.gender') }}<span class="fa fa-info-circle toggle" @click="$parent.$parent.toggleCollapse('gender')"></span></span>
                        <div id="gender" class="collapse" style="">
                        <div class="card">
                            <div class="card-body">
                                <p>{{ $t('tooltip.gender') }}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="input_wrap col-xs-5 col-sm-3 col-md-2">
    	              <Field type="radio" name="gender" id="female" value="F" form="persoonlijk" class="required" v-model="gender" data-progress="gender" @change="$parent.$parent.triggerGaEvent('Persoonlijk', 'Vraag 1: ' + $t('label.gender'), 'Vrouw')" />
    	              <label for="female" data-test="gender">
    	                  {{ $t('option.female') }}
                      </label>
    	            </div>
                    <div class="input_wrap col-xs-5 col-sm-3 col-md-2">
    	              <Field type="radio" name="gender" id="male" value="M" class="required" v-model="gender" @change="$parent.$parent.triggerGaEvent('Persoonlijk', 'Vraag 1: ' + $t('label.gender'), 'Man')" />
    	              <label for="male" data-test="gender">
    	                  {{ $t('option.male') }}
                      </label>
    	            </div>
                    <span class="error" v-show="errors.gender">{{ $t('error.required') }}</span>
                   </div>
    	       </div>
    	    </fieldset>
            <fieldset class="input_style">
                <!-- add class: error -->
                <div class="form-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                    <div class="row">

                        <div class="col-md-12">
                            <div class="row error-scroll-pos">
                                <div class="col-md-6" :class="{'error': errors.firstName}">
                                    <label for="firstName">{{ $t('label.firstName') }}
                                    </label>
                                    <Field type="text" name="firstName" id="firstName" data-test="firstName" class="form-control required" autocomplete="no" v-model="firstName"  data-progress="firstName" @change="$parent.$parent.triggerGaEvent('Persoonlijk', 'Vraag 2: ' + $t('label.firstName'), 'Ja')" />
                                    <span class="error" v-show="errors.firstName">{{ $t('error.required') }}</span>
                                </div>
                                <div class="col-md-6" :class="{'error': errors.lastName}">
                                    <label for="lastName">{{ $t('label.lastName') }}</label>
                                    <Field type="text" v-model="lastName" name="lastName" id="lastName" data-test="lastName" class="form-control required" autocomplete="no" data-progress="lastName"  @change="$parent.$parent.triggerGaEvent('Persoonlijk', 'Vraag 3: ' + $t('label.lastName'), 'Ja')" />
                                    <span class="error" v-show="errors.lastName">{{ $t('error.required') }}</span>
                                </div>
                                <div class="col-md-12 error-scroll-pos" :class="{'error': errors.passportName}">
                                    <label for="passportName">{{ $t('label.passportName') }} <span class="fa fa-info-circle toggle" @click="$parent.$parent.toggleCollapse('passportName')"></span></label>
                                    <!-- element to collapse -->
                                    <div id="passportName" class="collapse" style="">
                                      <div class="card">
                                        <div class="card-body">
                                          <p>{{ $t('tooltip.passport') }}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <Field type="text" name="passportName" id="passportName" data-test="passportName" class="form-control required" autocomplete="no" form="persoonlijk" v-model="passportName" data-progress="passportName" @change="$parent.$parent.triggerGaEvent('Persoonlijk', 'Vraag 4: ' + $t('label.passportName'), 'Ja')" />
                                    <span class="error" v-show="errorspassportName">{{ $t('error.required') }}</span>
                                </div>
                                <div class="col-md-12 error-scroll-pos" :class="{'error': errors.initialsPassport}">
                                    <label for="initialsPassport">{{ $t('label.initialsPassport') }}</label>
                                    <Field type="text" name="initialsPassport" id="initialsPassport" data-test="initialsPassport" class="form-control required" autocomplete="no" form="persoonlijk" v-model="initialsPassport" data-progress="initialsPassport" @change="$parent.$parent.triggerGaEvent('Persoonlijk', 'Vraag 5: ' + $t('label.initialsPassport'), 'Ja')" />
                                    <span class="error" v-show="errors.initialsPassport">{{ $t('error.required') }}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </fieldset>
            <fieldset class="input_style">
                <!-- add class: error -->
                <div class="form-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                    <div class="row">

                        <div class="col-md-12">
                            <div class="row error-scroll-pos">
                                <div class="col-md-6" :class="{'error': errors.email}">
                                    <label for="email">{{ $t('label.email') }}
                                    </label>
                                    <Field type="text" name="email" id="email" data-test="email" class="form-control required email" oncopy="return false;" onpaste="return false;" oncut="return false;" autocomplete="no" form="persoonlijk" v-model="email" data-vv-delay="1000" @change="$parent.$parent.triggerGaEvent('Persoonlijk', 'Vraag 6a: ' + $t('label.email'), 'Ja')" />
                                    <span class="error" v-show="errors.email">{{ $t('error.email') }}</span>
                                </div>
                                <div class="col-md-6" :class="{'error': errors.emailConfirmation}">
                                    <label for="emailConfirmation">{{ $t('label.emailConfirmation') }}
                                    </label>
                                    <Field type="text" name="emailConfirmation" id="emailConfirmation" data-test="emailConfirmation" oncopy="return false;" onpaste="return false;" oncut="return false;" autocomplete="no" class="form-control required" form="persoonlijk" v-model="emailConfirmation" data-vv-delay="1000" data-progress="emailConfirmation" @change="$parent.$parent.triggerGaEvent('Persoonlijk', 'Vraag 6b: ' + $t('label.emailConfirmation'), 'Ja')" />
                                    <span class="error" v-show="errors.emailConfirmation">{{ $t('error.emailConfirmation') }}</span>
                                </div>
                                <div class="col-md-12 error-scroll-pos" :class="{'error': errors.phone}">
                                    <label for="phone">{{ $t('label.phone') }}
                                    </label>
                                    <Field type="number" v-model="phone" name="phone" id="phone" data-test="phone" class="form-control required" autocomplete="no" @change="replaceLandCode(), $parent.$parent.triggerGaEvent('Persoonlijk', 'Vraag 7: ' + $t('label.phone'), 'Ja')" data-vv-delay="1000" data-progress="phone"  />
                                    <span class="error" v-show="errors.phone">{{ $t('error.required') }}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </fieldset>
            <fieldset class="input_style">
                <!-- add class: error -->
                <div class="form-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                    <div class="row">

                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row error-scroll-pos">
                                        <div class="col-md-4" :class="{'error': errors.zipcodeNumerals}">
                                            <label for="zipcodeNumerals">{{ $t('label.postcode') }}<span class="smaller">({{ $t('helptext.postcode_numbers') }})</span></label>
                                            <Field type="number" pattern="[1-9][0-9]{4}" autocomplete="no" maxlength="4" name="zipcodeNumerals" id="zipcodeNumerals" data-test="zipcodeNumerals" class="form-control required" placeholder="1234" v-model="zipcodeNumerals" @change="fetchAddress(), $parent.$parent.triggerGaEvent('Persoonlijk', 'Vraag 8a: ' + $t('label.postcode') + ' ('+ $t('helptext.postcode_numbers') + ')' , 'Ja')"  data-vv-delay="1000" data-progress="zipcodeNumerals" />
                                            <span class="error" v-show="errors.zipcodeNumerals">{{ $t('error.required') }}</span>
                                        </div>
                                        <div class="col-md-4" :class="{'error': errors.zipcodeLetters}">
                                            <label for="zipcodeLetters">{{ $t('label.postcode') }}<span class="smaller">({{ $t('helptext.postcode_letters') }})</span></label>
                                            <Field type="text" pattern="[a-zA-Z]{2}" autocomplete="no" maxlength="2" name="zipcodeLetters" id="zipcodeLetters" data-test="zipcodeLetters" class="form-control required" placeholder="AB" v-model="zipcodeLetters" @change="fetchAddress(), $parent.$parent.triggerGaEvent('Persoonlijk', 'Vraag 8b: ' + $t('label.postcode') + ' ('+ $t('helptext.postcode_letters') + ')' , 'Ja')"  data-vv-delay="1000" data-progress="zipcodeLetters" />
                                            <span class="error" v-show="errors.zipcodeLetters">{{ $t('error.required') }}</span>
                                        </div>
                                        <div class="col-md-4" :class="{'error': errors.streetNumber}">
                                            <label for="streetNumber">{{ $t('label.housenumber') }}</label>
                                            <Field type="text" name="streetNumber" autocomplete="no" id="streetNumber" data-test="streetNumber" class="form-control required" placeholder="23a" v-model="streetNumber" @change="fetchAddress(), $parent.$parent.triggerGaEvent('Persoonlijk', 'Vraag 8c: ' + $t('label.housenumber') , 'Ja')"  data-progress="streetNumber" />
                                            <span class="error" v-show="errors.streetNumber">{{ $t('error.required') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 error-scroll-pos" :class="{'error': errors.street}">
                                    <label for="street">{{ $t('label.street') }}
                                    </label>
                                    <Field type="text" :disabled="isDisabled ? true : null" name="street" ref="street" id="street" autocomplete="no" data-test="street" class="form-control required" tabindex="-1" v-bind:class="{'is-disabled': isDisabled, 'is-loading': loading}" v-model="street" />
                                    <span class="error" v-show="errors.street">{{ $t('error.required') }}</span>

                                </div>
                                <div class="col-md-6 error-scroll-pos" :class="{'error': errors.city}">
                                    <label for="city">{{ $t('label.city') }}
                                    </label>
                                    <Field type="text" :disabled="isDisabled ? true : null" name="city" id="city" autocomplete="no" data-test="city" class="form-control required" tabindex="-1" v-bind:class="{'is-disabled': isDisabled, 'is-loading': loading}" v-model="city" />
                                    <span class="error" v-show="errors.city">{{ $t('error.required') }}</span>
                                </div>
                                <div class="col-md-12" v-show="isDisabled">
                                    <a href="#" v-on:click.prevent="forceAddressValidated">{{ $t('link.correctAddress') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <div  class="wrapper-bottom">
      <div class="app-footer clearfix">
        <router-link to="/medisch" class="btn grey prev"><span>{{ $t("button.prev") }}</span></router-link>
        <button class="btn orange next" ><span>{{ $t("button.next") }}</span></button>
      </div>
    </div>
  </Form>
</template>
<script>
    import Vue from 'vue';
    import $ from 'jquery';
    import vuexMixin from '../../../mixins/vuexMixin';
    import {mapState} from 'vuex';

    import * as types from '../../../store/mutation-types';
    import { Form, Field } from 'vee-validate';
    import * as yup from 'yup';
    import PhoneNumber from 'awesome-phonenumber'

	export default {
        name: 'form-personal',
        data() {
            return {
                gender: '',
                firstName: '',
                lastName: '',
                passportName: '',
                initialsPassport: '',
                email: '',
                emailConfirmation: '',
                phone: '',
                zipcodeNumerals: '',
                zipcodeLetters: '',
                streetNumber: '',
                street: '',
                city: '',
                addressValidated: false,
                loading: false,
            }
        },
        components: {
          Form,
          Field,
        },
        mixins: [vuexMixin],
        setup() {        
          const schema = yup.object({
            gender: yup.string().required(),
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            passportName: yup.string().required(),
            initialsPassport: yup.string()
              .required()
              .max(12),
            email: yup.string()
              .email()
              .required(),
            emailConfirmation: yup.string()
              .required()
              .oneOf([yup.ref('email')]),
            phone: yup.string()
            .required()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10)
            .max(12)
            .test('validPhone', 'Ongeldig telefoonnummer', 
                function(value) {
                // 
                let pn = new PhoneNumber(value.toString(), 'NL')
                return pn.isValid()
                }
            ),
            zipcodeNumerals: yup.string().required(),
            zipcodeLetters: yup.string().required(),
            streetNumber: yup.string().required(),
            street: yup.string().required(),
            city: yup.string().required(),
          });
          return {
            schema
          };
        },
        methods: {
          onSubmit() {
            this.personalValidation();
          },
          personalValidation: function(){
            // lets get rid of components variables
            let formData = this.$data;
            // delete formData['countries'];
            this.$store.commit('user/'+types.SET_PERSONAL, formData);

            this.$router.push('/definitief');
          },
            setProgress() {
                this.$parent.$parent.setProgress(50);
            },
            fetchAddress() {
                if (this.zipcodeNumerals && this.zipcodeLetters && this.streetNumber) {
                    this.loading = true;
                    this.$store.dispatch('user/fetchAddress', this.address).then((response) => {
                        this.street = response.street;
                        this.city = response.city;
                        this.loading = false;
                    }).catch((error) => {
                        this.street = '';
                        this.city = '';
                        this.loading = false;
                    });
                }
            },
            replaceLandCode() {
                var pn = this.phone;
                pn = pn.replace("+", "00");
                this.phone = pn;
            },
            forceAddressValidated() {
                this.addressValidated = true;
                // this.focus();
            },
        },
        created() {
            this.fillDataFromStore(this.personal);
            this.fetchAddress();
            this.$nextTick(function () {
                this.setProgress();
            });
        },
        computed: {
            ...mapState('user', [
                'personal',
            ]),
            address() {
                return this.zipcodeNumerals + this.zipcodeLetters.toUpperCase() + '+' + this.streetNumber;
            },
            isDisabled() {
                return !this.addressValidated;
            }
        },
	}
</script>

<style>
    .is-disabled {
        background-color: #e4e4e4 !important;
        pointer-events: none;
    }
</style>
