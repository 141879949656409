<template>
	<div class="row">
    <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
		  <fieldset class="input_style">
        <!-- add class: error -->
        <div class="form-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
          <div class="row">
            <div class="col-md-12">
              <div class="row error-scroll-pos">
                <div class="col-md-6" :class="{'error': errors.firstName}">
                  <label for="firstName">{{ $t('label.firstName') }}</label>
                  <Field type="text" name="firstName" id="firstName" data-test="firstName" class="form-control required" autocomplete="no" v-model="firstName" />
                  <span class="error" v-show="errors.firstName">{{ $t('error.required') }}</span>
                </div>
                <div class="col-md-6" :class="{'error': errors.lastName}">
                  <label for="lastName">{{ $t('label.lastName') }}</label>
                  <Field type="text" name="lastName" id="lastName" data-test="lastName" class="form-control required" autocomplete="no" v-model="lastName" />
                  <span class="error" v-show="errors.lastName">{{ $t('error.required') }}</span>
                </div>
              </div>
              <div class="row error-scroll-pos">
                <div class="col-md-6" :class="{'error': errors.email}">
                  <label for="email">{{ $t('label.email') }}</label>
                  <Field type="email" name="email" id="email" data-test="email" class="form-control" autocomplete="no" v-model="email"  />
                  <span class="error" v-show="errors.email">{{ $t('error.email') }}</span>
                </div>
                <div class="col-md-6" :class="{'error': errors.phone}">
                  <label for="phone">{{ $t('label.phone') }}</label>
                  <Field type="number" name="phone" id="phone" data-test="phone" class="form-control required" autocomplete="no" v-model="phone" @change="replaceLandCode" data-vv-delay="1000" />
                  <span class="error" v-show="errors.phone">{{ $t('error.required') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="input_style">
		<!-- add class: error -->
		<div class="form-group select-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
		    <div class="row">
                <div class="col-md-12">
                    <span class="label">{{ $t('label.birthDate') }}</span>
                </div>
                <div class="col-md-9">
                    <div class="row error-scroll-pos">
                        <div class="input_wrap col-md-3">
                            <label for="day">
                                <span class="fa fa-calendar-o"></span>
                                <span class="placeholder" :class="{'hidden': day}">{{ $t('placeholder.day') }}</span>
                            </label>
                            <Field as="select" autocomplete="off" class="form-control select-day required" name="day" id="day" data-msg="Verplicht" v-model="day" >
                                <option v-for="dayNr in days" :value="dayNr">{{ dayNr }}</option>
                            </Field>
                            <span class="error" v-show="errors.day">{{ $t('error.required') }}</span>
                        </div>
                        <div class="input_wrap col-md-6">
                            <label for="month">
                                <span class="placeholder" :class="{'hidden': month}">{{ $t('placeholder.month') }}</span>
                            </label>
                            <Field as="select" autocomplete="off" name="month" id="month" class="form-control select-month required" v-model="month">
                                <option value="01">{{ $t('option.jan') }}</option>
                                <option value="02">{{ $t('option.feb') }}</option>
                                <option value="03">{{ $t('option.mar') }}</option>
                                <option value="04">{{ $t('option.apr') }}</option>
                                <option value="05">{{ $t('option.may') }}</option>
                                <option value="06">{{ $t('option.jun') }}</option>
                                <option value="07">{{ $t('option.jul') }}</option>
                                <option value="08">{{ $t('option.aug') }}</option>
                                <option value="09">{{ $t('option.sep') }}</option>
                                <option value="10">{{ $t('option.oct') }}</option>
                                <option value="11">{{ $t('option.nov') }}</option>
                                <option value="12">{{ $t('option.dec') }}</option>
                            </Field>
                            <span class="error" v-show="errors.month">{{ $t('error.required') }}</span>
                        </div>
                        <div class="input_wrap col-md-3">
                            <label for="year">
                                <span class="placeholder" :class="{'hidden': year}">{{ $t('placeholder.year') }}</span>
                            </label>
                            <Field as="select" autocomplete="off" class="form-control select-year required" name="year" id="year" v-model="year">
                                <option v-for="yearNr in years" :value="yearNr">{{ yearNr }}</option>
                            </Field>
                            <span class="error" v-show="errors.year">{{ $t('error.required') }}</span>
                        </div>
                    </div>
                </div>
                <Field name="birthdate" v-model="birthdate" class='hidden' />
                <div class="col-md-12 error-message" v-show="errors.birthdate">
                    <span class="attention">
                        <span class="ion ion-android-alert"></span>
                        <p v-html="$t('error.age')"></p>
                    </span>
                </div>
            </div>
		</div>
		</fieldset>
		<fieldset class="input_style">
			<!-- add class: show-error-message  --><!-- add class: error -->
			<div class="form-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
				<div class="row">
                    <div class="col-md-9">
                        <div class="row error-scroll-pos">
                            <div class="col-md-6" :class="{'error': errors.weight}">
                                <label for="gewicht">{{ $t('label.weight') }}
                                    <span class="additional gewicht">kg</span>
                                </label>
                                <Field type="number" name="weight" id="weight" data-test="weight"  placeholder="je gewicht" v-model="weight" class="form-control required" />
                                <span class="error" v-show="errors.weight">{{ $t('error.weight') }}</span>
                            </div>
                            <div class="col-md-6" :class="{'error': errors.height}">
                                <label for="lengte">{{ $t('label.height') }}
                                    <span class="additional lengte">cm</span>
                                </label>
                                <Field type="number" name="height" id="height" data-test="height" placeholder="je lengte" v-model="height" class="form-control required" />
                                <span class="error" v-show="errors.height">{{ $t('error.height') }}</span>
                            </div>
                        </div>
                    </div>
                    <Field type="hidden" name="bmi" v-model="bmi" data-vv-delay="1000"/>
                    <div class="col-md-12 error-message" v-show="errors.bmi">
                        <span class="attention">
                            <span class="ion ion-android-alert"></span>
                            <p v-html="$t('error.bmi')"></p>
                        </span>
                    </div>
                </div>
			</div>
		</fieldset>
		<fieldset class="input_style">
          <!-- add class: show-error-message --><!-- add class: error -->
	      <div class="form-group radio-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
	          <div class="row error-scroll-pos">

                    <div class="col-md-12">
                        <span class="label">{{ $t('label.healthy') }}</span>
                    </div>
                    <div class="input_wrap col-xs-5 col-sm-3 col-md-2">
    	              <Field type="radio" name="medicalAffection" id="health_yes" value="0" form="event" class="required" v-model="medicalAffection" />
    	              <label for="health_yes" data-test="healthy">
    	                  {{ $t('option.yes') }}
                      </label>
    	            </div>
                    <div class="input_wrap col-xs-5 col-sm-3 col-md-2">
    	              <Field type="radio" name="healthy" id="health_no" value="1" form="event" class="required" v-model="medicalAffection" />
    	              <label for="health_no" data-test="healthy">
    	                  {{ $t('option.no') }}
                      </label>
    	            </div>
					<div class="col-md-12 error-message" v-show="errors.healthy">
                        <span class="attention">
                            <span class="ion ion-android-alert"></span>
                            <span v-html="$t('error.eventHealthy')"></span>
                        </span>
                    </div>

               </div>
	       </div>
	    </fieldset>
		<fieldset class="input_style">
            <!-- add class: show-error-message --><!-- add class: error -->
            <div class="form-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                <div class="row error-scroll-pos">

                    <div class="col-md-12">
                        <label for="swabCode">{{ $t('label.barcode') }}
                        </label>
                        <Field type="text" name="swabCode" id="swabCode" data-test="swabCode" class="form-control required" form="event" v-model="swabCode" />
						<input class='hidden' name="validcode" >
                        <span class="error" v-show="errors.swabCode">{{ $t('error.required') }}</span>
                        <div class="error-message" v-show="errors.swabCode">
							<span class="attention">
								<span class="ion ion-android-alert"></span>
								<p v-html="$t('error.barcode')"></p>
							</span>
						</div>
                    </div>
                </div>
            </div>
        </fieldset>
        <div class="form-group result-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
            <div class="row">
                <div class="col-md-12">
                    <button tabindex="-1"
                        class="wizard-btn btn green submit btn-large"
                        :class="{'is-loading': loadingStates.user}"
                    >
                        <span class="text">{{ $t('button.submit') }}</span>
                        <span class="loader">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </button>                    
                </div>
            </div>
        </div>
    </Form>
	</div>
</template>

<script>
    import Vue from 'vue';
    import $ from 'jquery';
    import {mapState} from 'vuex';
    import * as types from '../../store/mutation-types';
    import objectMerge from 'object-merge';

    import { Form, Field } from 'vee-validate';
    import * as yup from 'yup';
    import PhoneNumber from 'awesome-phonenumber'



    // Vue.use(VeeValidate);

    export default {
        name: 'event-form',
        data() {
            return {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                day: '',
                month: '',
                year: '',
                weight: '',
                height: '',
                medicalAffection: '',
                swabCode: '',
            }
        },
        components: {
          Form,
          Field
        },
        setup() {        
          let minAgeDate = new Date(); 
          minAgeDate.setFullYear(minAgeDate.getFullYear() -18);

          let maxAgeDate = new Date(); 
          maxAgeDate.setFullYear(maxAgeDate.getFullYear() -55);


          const schema = yup.object({
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            day: yup.string().required(),
            month: yup.string().required(),
            year: yup.string().required(),
            birthdate: yup.string().when(['day','month', 'year'], (day,month,year) => {
              if (day && month && year) {
                return yup.string().test(
                  'is-correct-age',
                  'Incorrect age',
                  function () {
                    var age = new Date(year,month,day).getTime();
                    var maxAgeTime = maxAgeDate.getTime();
                    var minAgeTime = minAgeDate.getTime();

                    return age <= minAgeTime && age >= maxAgeTime;
                  },
                );
                  
              }
            }),
            weight: yup.number().required(),
            height: yup.number().required(),
            bmi: yup.string().when(['weight','height'], (weight,height) => {
              if (weight && height) {
                return yup.number().min(17).max(30)
              }
            }),
            healthy: yup.mixed().required().equals(["0"]),
            email: yup.string().email().required(),
            phone: yup.number().required().min(10).test('validPhone', 'Ongeldig telefoonnummer', 
            function(value) {
              let pn = new PhoneNumber(value.toString(), 'NL')
              return pn.isValid()
                // your logic
            }),
            swabCode: yup.string().required().min(7).max(7).test('validSwabcode', 'Ongeldige Swabcode', 
            function(value) {
              var number = value;
              var sumTable = [
                  [0,1,2,3,4,5,6,7,8,9],
                  [0,2,4,6,8,1,3,5,7,9]
              ];
              var sum = 0;
              var flip = 0;

              for (var i = number.length - 1; i >= 0; i--) {
                sum += sumTable[flip++ & 0x1][number[i]];
              }

              var validsum = sum % 10 === 0;
              if(validsum){
                  return true;
              }else{
                  return false;
              }
            }),

          });
          return {
            schema
          };
        },
        methods: {
          onSubmit() {
            this.$store.commit('app/'+types.LOADING_USER);
            this.$store.commit('user/'+types.SET_EVENT, this.$data);
            var birthdate = {birthDate: this.year+"-"+this.month+"-"+this.day};
            var event = JSON.parse(JSON.stringify(objectMerge(this.event, birthdate)));
            delete event["day"],delete event["month"],delete event["year"];
            this.$store.dispatch('user/saveEventDonor',event).then((response) => {
                this.$store.commit('user/'+types.RESET_NOTICE);
                if (response.data) {
                    this.$store.commit('user/'+types.RESET_EVENT);
                    this.$store.commit('app/'+types.USER_LOADED);
                    this.$router.push('/event/bedankt');
                }
                return false;
            }).catch((error) => {
                this.toErrorArray(error.errors);
                this.scrollTo();
                this.$store.commit('app/'+types.USER_LOADED);
                return false;
            });
          },
            validateAge(birthday, minage, maxage, separator) {
                if (!parseInt(minage) || !parseInt(maxage)) {
                    return false;
                }
                var split = birthday.split(separator);
                var dateDay = split[0];
                var dateMonth = split[1];
                var dateFullYear = split[2];
                var mydate = new Date();

                mydate.setFullYear(dateFullYear, dateMonth-1, dateDay);

                var currdate = new Date();
                var minDate = new Date();
                var maxDate = new Date();
                minDate.setFullYear(mydate.getFullYear() + minage, dateMonth-1, dateDay -1);
                maxDate.setFullYear(mydate.getFullYear() + maxage, dateMonth-1, dateDay);
                if((currdate - minDate) > 0 && (currdate - maxDate) < 0){
                    return true;
                }
                return false;
            },
            replaceLandCode() {
                var pn = this.phone;
                pn = pn.replace("+", "00");
                this.phone = pn;
            },
            toErrorArray: function(errors){
                var errorArray = [];
                var val;
                for (val of errors) {
                    var translation = "'error."+val['field']+"'";
                    errorArray[val['field']] = translation;
                }
                this.$store.commit('user/'+types.SET_NOTICE,JSON.parse(JSON.stringify(errors)));

                return;
            },
            getFirstErrorElement: function() {
                return this.$el.querySelector('[name="' + this.$validator.errors.items[0].field + '"]');
            },            
            scrollTo: function(element) {
                if (element) {
                    let $element = $(element);
                    if ($element.parents('.error-scroll-pos').length) { // bind a class to to desired scroll position
                        window.scrollTo(0, $element.parents('.error-scroll-pos').offset().top - 50); // 50 as extra offset
                    }
                } else {
                    window.scrollTo(0,0); // previously
                    // document.body.scrollTop = document.documentElement.scrollTop = 0;
                }
            },            
        },
        computed: {
            ...mapState('user', [
                'event',
            ]),
            ...mapState ('app', [
                'loadingStates'
            ]),
            days() {
                let days = [];
                for (let index = 1; index <= 31; index++) {
                    days.push(index);
                }
                return days;
            },
            years() {
                let years = [];
                for (let index = 2006; index >= 1940; index--) {
                    years.push(index);
                }
                return years;
            },
            birthdate() {
                return this.day + '/' + this.month  + '/' + this.year;
            },
            bmi(){
                return this.weight / (this.height / 100) / (this.height / 100);
            },
            validcode() {
                return this.swabCode;
            },
        },

  }
</script>
