<template >
    <div class="wrapper-center">
        <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }" v-on:change="setProgress()">
            <div class="row">
                <!-- eventcode -->
                <fieldset class="input_style eventArea" v-if="eventBlock">
                    <div class="form-group">
                        <div class="error-scroll-pos">
                            <label for="eventCode">{{ $t('label.eventCode') }}</label>
                            <template v-if="!validEventCode">
                                <p>{{ $t('helptext.eventCode') }} <a v-on:click.prevent="showEventCode = true" v-if="!showEventCode">{{ $t('link.eventCode') }}</a><span v-if="showEventCode">{{ $t('link.eventCode') }}</span></p>
                                <p v-if="showEventCode">
                                    <br/>
                                    {{ $t('eventCode.helpLabel') }}
                                </p>
                                <div v-show="showEventCode" class="input-group">
                                    <Field type="text" name="eventCode" id="eventCode" class="form-control" v-model="eventCode"  :placeholder="$t('placeholder.eventCode')" />
                                    <a v-on:click="checkEventCode" class="btn button orange" v-bind:class="{'is-loading':loadingEventCode}">
                                        <span class="text">{{ $t('button.eventCode') }}</span>
                                        <span class="loader">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </span>
                                    </a>
                                    <span class="error" v-if="errors.eventCode">{{ $t('error.eventCodeValidation') }}</span>
                                    <span class="error" v-show="eventCodeError">{{ $t('error.eventCode') }}</span>
                                    <p>{{ $t('text.eventCode') }}</p>
                                </div>
                            </template>
                            <template v-else>
                                <p>{{ $t('eventCode.success') }}</p>
                                <span class="fa fa-check animated tada"></span>
                            </template>
                        </div>
                    </div>
                </fieldset>
                <!-- /eventcode -->

                <!-- Resident NL -->
                <h2>{{ $t("nav.general")}}</h2>
                <fieldset class="input_style">
                    <!-- add class: show-error-message --><!-- add class: error -->
                    <div class="form-group radio-group col-md-10 col-md-offset-1 col-lg-7 col-lg-offset-2" >
                        <div class="row error-scroll-pos">
                            <div class="col-md-12">
                                <span class="label">{{ $t('label.residentNl') }}</span>
                            </div>
                            <div class="input_wrap col-md-2">
                            <Field type="radio" name="residentNl" id="nederland" value="1"  v-model="residentNl"  class="required" data-progress="country" @change="$parent.$parent.triggerGaEvent('Algemeen', 'Vraag 1: ' + $t('label.residentNl'), 'Ja')"/>
                            <label for="nederland" >
                                {{ $t('option.yes') }}
                            </label>
                            </div>
                            <div class="input_wrap col-md-2">
                            <Field type="radio" name="residentNl" id="buitenland" value="0"  v-model="residentNl" class="required"  @change="$parent.$parent.triggerGaEvent('Algemeen', 'Vraag 1: ' + $t('label.residentNl'), 'Nee')" />
                            <label for="buitenland" >
                                {{ $t('option.no') }}
                            </label>
                            </div>
                            <div class="col-md-12 error-message" v-if="errors.residentNl">
                                <span class="attention">
                                    <span class="ion ion-android-alert"></span>
                                    <span v-html="$t('error.residentNl')"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <!-- /Resident NL -->

                <!-- Birthdate -->
                <fieldset class="input_style">
                    <!-- add class: error -->
                    <div class="form-group select-group col-md-10 col-md-offset-1 col-lg-7 col-lg-offset-2">
                    <div class="row">
                        <div class="col-md-12">
                            <span class="label">{{ $t('label.birthDate') }}</span>
                        </div>
                        <div class="col-md-10">
                            <div class="row error-scroll-pos">
                                <div class="input_wrap col-md-3">
                                    <label for="day">
                                        <span class="fa fa-calendar-o"></span>
                                        <span class="placeholder" :class="{'hidden': day}">{{ $t('placeholder.day') }}</span>
                                    </label>
                                    <Field as="select" autocomplete="off" class="form-control select-day required" name="day" id="day" v-model="day" @change="$parent.$parent.triggerGaEvent('Algemeen', 'Vraag 2a: ' + $t('placeholder.day'), 'Ja')" >
                                        <option v-for="dayNr in days" :value="dayNr" :key="dayNr">{{ dayNr }}</option>
                                    </Field>
                                    <span class="error" v-if="errors.day">{{ $t('error.required') }}</span>
                                </div>
                                <div class="input_wrap col-md-6">
                                    <label for="month">
                                        <span class="placeholder" :class="{'hidden': month}">{{ $t('placeholder.month') }}</span>
                                    </label>
                                    <Field as="select" autocomplete="off" name="month" id="month" class="form-control select-month required" v-model="month" @change="$parent.$parent.triggerGaEvent('Algemeen', 'Vraag 2b: ' + $t('placeholder.month'), 'Ja')" >
                                        <option value="01">{{ $t('option.jan') }}</option>
                                        <option value="02">{{ $t('option.feb') }}</option>
                                        <option value="03">{{ $t('option.mar') }}</option>
                                        <option value="04">{{ $t('option.apr') }}</option>
                                        <option value="05">{{ $t('option.may') }}</option>
                                        <option value="06">{{ $t('option.jun') }}</option>
                                        <option value="07">{{ $t('option.jul') }}</option>
                                        <option value="08">{{ $t('option.aug') }}</option>
                                        <option value="09">{{ $t('option.sep') }}</option>
                                        <option value="10">{{ $t('option.oct') }}</option>
                                        <option value="11">{{ $t('option.nov') }}</option>
                                        <option value="12">{{ $t('option.dec') }}</option>
                                    </Field>
                                    <span class="error" v-if="errors.month">{{ $t('error.required') }}</span>
                                </div>
                                <div class="input_wrap col-md-3">
                                    <label for="year">
                                        <span class="placeholder" :class="{'hidden': year}">{{ $t('placeholder.year') }}</span>
                                    </label>
                                    <Field as="select" autocomplete="off" class="form-control select-year required" name="year" id="year" v-model="year" data-progress="birthdate" v-on:blur="FieldIsValid" @change="$parent.$parent.triggerGaEvent('Algemeen', 'Vraag 2c: ' + $t('placeholder.year'), 'Ja')">
                                        <option v-for="yearNr in years" :value="yearNr" :key="yearNr">{{ yearNr }}</option>
                                    </Field>
                                    <span class="error" v-if="errors.year">{{ $t('error.required') }}</span>
                                </div>
                            </div>
                        </div>
                       
                        <Field type="hidden" name="birthdate"  v-model="birthdate" @change="$parent.$parent.triggerGaEvent('Algemeen', 'Vraag 1: ' + $t('label.residentNl'), 'Ja')" />
                        <div class="col-md-12 error-message" v-if="errors.birthdate">
                            <span class="attention">
                                <span class="ion ion-android-alert"></span>
                                <p v-if="under18" v-html="$t('error.under18')"></p>
                                <p v-else v-html="$t('error.age')"></p>
                            </span>
                        </div>
                    </div>
                    </div>
                </fieldset>
                <!-- /Birthdate -->

                <!-- Height Weight and BMI -->
                <fieldset class="input_style">
                    <!-- add class: show-error-message  --><!-- add class: error -->
                    <div class="form-group col-md-10 col-md-offset-1 col-lg-7 col-lg-offset-2 show-error-message">
                        <div class="row error-scroll-pos">

                            <div class="col-md-10">
                                <div class="row">
                                    <div class="col-md-6" :class="{'error': errors.length}">
                                        <label for="gewicht">{{ $t('label.weight') }}
                                            <span class="additional gewicht">kg</span>
                                        </label>
                                        <Field type="number" name="weight" id="gewicht" :placeholder="$t('placeholder.weight')" v-model="weight" class="form-control required" @keypress="ignorePeriod($event)" data-progress="weight" @change="$parent.$parent.triggerGaEvent('Algemeen', 'Vraag 3a: ' + $t('label.weight'), 'Ja')" />
                                        <span class="error" v-if="errors.weight">{{ $t('error.weight') }}</span>
                                    </div>
                                    <div class="col-md-6" :class="{'error': errors.length}">
                                        <label for="lengte">{{ $t('label.height') }}
                                            <span class="additional lengte">cm</span>
                                        </label>
                                        <Field type="number" name="height" id="lengte" :placeholder="$t('placeholder.length')" v-model="height" class="form-control required" data-progress="height" @keypress="ignorePeriod($event)" @change="removePeriod(), $parent.$parent.triggerGaEvent('Algemeen', 'Vraag 3b: ' + $t('label.height'), 'Ja')"  />
                                        <span class="error" v-if="errors.height">{{ $t('error.height') }}</span>
                                    </div>
                                </div>
                            </div>
                            <Field type="hidden" name="bmi" v-model="bmi" data-vv-delay="1000"/>
                            <div class="col-md-12 error-message" v-if="errors.bmi" >
                                <span class="attention">
                                    <span class="ion ion-android-alert"></span>
                                    <p v-html="$t('error.bmi')"></p>
                                </span>
                            </div>

                        </div>
                    </div>
                </fieldset>
                <!-- /Height Weight and BMI -->

                <!-- Origin parents -->
                <fieldset class="input_style">
                    <div class="form-group radio-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                        <div class="row error-scroll-pos">
                            <div class="col-md-12">
                                <span class="label">{{ $t('label.originParents') }} <span class="fa fa-info-circle toggle" @click="$parent.$parent.toggleCollapse('originParents')"></span></span>
                                <!-- element to collapse -->
                                <div id="originParents" class="collapse" style="">
                                  <div class="card">
                                    <div class="card-body">
                                       <p>{{ $t('tooltip.originParents') }}</p>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="input_wrap col-md-2">
                                <Field type="radio" name="otherContinent" id="afkomstig" value="true" v-model="otherContinent" data-progress="otherContinent" @change="$parent.$parent.triggerGaEvent('Algemeen', 'Vraag 5: ' + $t('label.originParents'), 'Ja')" />
                                <label for="afkomstig">
                                    {{ $t('option.yes') }}
                                </label>
                            </div>
                            <div class="input_wrap col-md-2">
                                <Field type="radio" name="otherContinent" id="niet_afkomstig" value="false" v-model="otherContinent" @change="$parent.$parent.triggerGaEvent('Algemeen', 'Vraag 5: ' + $t('label.originParents'), 'Nee')" />
                                <label for="niet_afkomstig">
                                    {{ $t('option.no') }}
                                </label>
                            </div>
                            <div class="input_wrap col-md-8">
                                <Field type="radio" name="otherContinent" id="onbekend" value="unknown" v-model="otherContinent" @change="$parent.$parent.triggerGaEvent('Algemeen', 'Vraag 5: ' + $t('label.originParents'), 'Ik wil of kan deze vraag niet beantwoorden')" />
                                <label for="onbekend">
                                    {{ $t('option.unknown') }}
                                </label>
                            </div>
                            <span class="error" v-if="errors.otherContinent">{{ $t('error.required') }}</span>
                        </div>
                    </div>
                    <div class="form-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2" v-show="otherContinent == 'true'">
                        <div class="row">
                            <div class="col-md-9">
                                <div class="row error-scroll-pos">
                                    <div class="col-md-6">
                                        <span class="label">{{ $t('label.originFather') }}</span>
                                        <div class="select-group origin-group input_wrap" :class="{'error': errors.originFather}">
                                            <label for="originFather">
                                                <span class="placeholder" :class="{'hidden': originFatherNotDefault}">{{ $t('label.originFather') }}</span>
                                            </label>
                                            <Field as="select" autocomplete="off" name="originFather" id="originFather" class="form-control select-month required" v-model="originFather" :deze="test" data-vv-validate-on="none">
                                                <option value="mixed">{{ $t('placeholder.mixedOrigin') }}</option>
                                                <option v-for="country in sortedCountries" :value="country.slug" :key="country.slug">{{ country.name }}</option>
                                            </Field>
                                            <span class="error" v-if="errors.originFather">{{ $t('error.required') }}</span>
                                        </div>
                                        <div class="input_wrap" :class="{'error': errors.originFatherMixed}" v-show="originFather == 'mixed'">
                                            <label for="originFatherMixed" class="hidden">{{ $t('label.mixedOrigin') }}</label>
                                            <Field class="form-control required" name="originFatherMixed" id="originFatherMixed" v-model="originFatherMixed" :placeholder="$t('label.mixedOrigin')"/>
                                            <span class="error" v-if="errors.originFatherMixed">{{ $t('error.required') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="label">{{ $t('label.originMother') }}</span>
                                        <div class="select-group origin-group input_wrap" :class="{'error': errors.originMother}">
                                            <label for="originMother">
                                                <span class="placeholder" :class="{'hidden': originMotherNotDefault}">{{ $t('label.originMother') }}</span>
                                            </label>
                                            <Field as="select" autocomplete="off" name="originMother" id="originMother" class="form-control select-month required" v-model="originMother" data-vv-validate-on="none">
                                                <option value="mixed">{{ $t('placeholder.mixedOrigin') }}</option>
                                                <option v-for="country in sortedCountries" :value="country.slug" :key="country.slug">{{ country.name }}</option>
                                            </Field>
                                            <span class="error" v-if="errors.originMother">{{ $t('error.required') }}</span>
                                        </div>
                                        <div class="input_wrap" :class="{'error': errors.originMotherMixed}" v-show="originMother == 'mixed'">
                                            <label for="originMotherMixed" class="hidden">{{ $t('label.mixedOrigin') }}</label>
                                            <Field class="form-control required" name="originMotherMixed" id="originMotherMixed" v-model="originMotherMixed" :placeholder="$t('label.mixedOrigin')"/>
                                            <span class="error" v-if="errors.originMotherMixed">{{ $t('error.required') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <!-- /Origin parents -->
          </div>
        <div  class="wrapper-bottom">
          <div class="app-footer clearfix">
            <button class="btn orange next" ><span>{{ $t("button.next") }}</span></button>
          </div>
        </div>
      </Form>
    </div>
</template>
<script>
  import vuexMixin from '../../../mixins/vuexMixin';
  import {mapState} from 'vuex';
  import CountriesNL from '../../../data/countries_nl.json';
  import CountriesEN from '../../../data/countries_en.json';
  import * as types from '../../../store/mutation-types';
  import { Form, Field } from 'vee-validate';
  import * as yup from 'yup';

	export default {  
    name: 'form-general',
    components: {
      Form,
      Field,
    },
    metaInfo: {
      title: 'My Example App',
      titleTemplate: '%s - Yay!',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    },
    data() {
      return {
        residentNl: '',
        day: '',
        month: '',
        year: '',
        weight: '',
        height: '',
        otherContinent: '',
        originFather: '',
        originMother: '',
        originFatherMixed: '',
        originMotherMixed: '',
        countries: CountriesNL, // defaults to dutch countries
        payingUser: false,
        eventCode: null,
        validEventCode: false,
        showEventCode: false,
        loadingEventCode: false,
        eventCodeError: false,
      }
    },
    mixins: [vuexMixin],
    setup() {
      let minAgeDate = new Date(); 
      minAgeDate.setFullYear(minAgeDate.getFullYear() -18);
      let maxAgeDate = new Date(); 
      maxAgeDate.setFullYear(maxAgeDate.getFullYear() -55);
      

      const schema = yup.object({
        residentNl: yup.mixed().equals(["1"]),
        day: yup.string().required(),
        month: yup.string().required(),
        year: yup.string().required(),
        birthdate: yup.string().when(['day','month', 'year'], (day,month,year) => {
          if (day && month && year) {
            return yup.string().test(
              'is-correct-age',
              'Incorrect age',
              function () {
                var age = new Date(year,month,day);
                // Fix date being 1 month later
                age.setMonth(age.getMonth() - 1)
                var maxAgeTime = maxAgeDate.getTime();
                var minAgeTime = minAgeDate.getTime();

                age = age.getTime();
                
                const isValidAge = age <= minAgeTime && age >= maxAgeTime;

                return isValidAge;
              },
            );
              
          }
        }),
        weight: yup.number().required().integer(),
        height: yup.number().required(),
        bmi: yup.string().when(['weight','height'], (weight,height) => {
          if (weight && height) {
            return yup.number().min(18).max(30)
          }
        }),
        otherContinent: yup.string().required(),
        originFather: yup.string().when(['otherContinent'], (otherContinent) => {
          if (true === otherContinent){
            return yup.string().required()
          }
        }),
        originMother: yup.string().when(['otherContinent'], (otherContinent) => {
          if (true === otherContinent){
            return yup.string().required()
          }
        }),
        originFatherMixed: yup.string().when('originFather', {
            is: 'mixed',
            then: yup.string().required(),
        }),
        originMotherMixed: yup.string().when('originMother', {
            is: 'mixed',
            then: yup.string().required(),
        }),
      });
      return {
          schema
      };
    },
    created() {
        this.fillDataFromStore(this.general);
        this.$nextTick(function () {
            this.setProgress();
        });
    },
    watch: {
      locale(locale) {
        this.changeCountryList(this.locale);
      },
      // Change nested values to empty when conditions are closed
      originFather: function(val) {
          if (val != 'mixed') {
              this.originFatherMixed = '';
          }
      },
      originMother: function(val) {
          if (val != 'mixed') {
              this.originMotherMixed = '';
          }
      },
      otherContinent: function(val) {
        if(val == 'false') {
          // Reset origin of parents to default label
          this.originFather = this.$i18n.t('placeholder.originFather');
          this.originMother = this.$i18n.t('placeholder.originMother');
        }
      },
    },
    methods: {
      onSubmit() {
        this.generalValidation();
      },
      generalValidation: function(){
        // lets get rid of components variables
        this.payingUser = this.checkPayingUser();
        let formData = this.$data;
        // delete formData['countries'];
        this.$store.commit('user/'+types.SET_GENERAL, formData);
        
        this.$router.push('/medisch');
      },
      FieldIsValid(e) {
        let name = e.target.name; 
        let val = e.target.value;
        var obj = {[name] : val};
        this.schema.isValid(obj)
        .then(function(valid) {
          if (valid) {
            this.$parent.$parent.triggerGaEvent
          }
        })
        .catch(function(error) {
          console.log(error);
        });
      },
      setProgress() {
          this.$parent.$parent.setProgress(0);
      },
      validateAge(birthday, minage, maxage, separator) {
        if (!parseInt(minage) || !parseInt(maxage)) {
          return false;
        }
        var split = birthday.split(separator);
        var dateDay = split[0];
        var dateMonth = split[1];
        var dateFullYear = split[2];
        var mydate = new Date();

        mydate.setFullYear(dateFullYear, dateMonth-1, dateDay);

        var currdate = new Date();
        var minDate = new Date();
        var maxDate = new Date();

        minDate.setFullYear(mydate.getFullYear() + minage, dateMonth-1, dateDay -1);
        maxDate.setFullYear(mydate.getFullYear() + maxage, dateMonth-1, dateDay);

        if((currdate - minDate) > 0 && (currdate - maxDate) < 0){
          return true;
        }
        return false;
      },
      checkPayingUser() {
        var originMother = this.originMother;
        var originFather = this.originFather;
        // const freeCountries = configService.get("originNoPayment");
        if (this.validateAge(this.birthdate, this.meta.paramAgePayment, 56, '/')) {
            // Older than 36 and not already paying user
            if (!this.payingUser) {
                // Or not required due to eventcode
                if (!this.validEventCode ) {
                    // if(!freeCountries.includes(originMother) && !freeCountries.includes(originFather)) {
                        return true;
                    // }
                }
            }
        }
        return false;
      },
      

      changeCountryList(locale) {
        switch (locale) {
          case 'en':
            this.countries = CountriesEN;
            break;
          default:
            this.countries = CountriesNL;
            break;
        }
      },
      checkEventCode() {
        let self = this;
        this.schema.isValid({
          eventCode: this.eventCode,
        })
        .then(function(valid) {
          if (!self.loadingEventCode && self.eventCode) {
            self.loadingEventCode = true;
            self.eventCodeError = false;
            self.$store.dispatch('user/getEventCode', self.eventCode).then((response) => {
              self.loadingEventCode = false;
              self.validEventCode = true;
              // reset paying user
              self.payingUser = false;
            }).catch((error) => {
              self.loadingEventCode = false;
              if (error.status_code == 404) {
                  self.eventCodeError = true;
              }
            });
          }
        });
      },
      ignorePeriod: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode === 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      removePeriod: function() {
        this.height = this.height.replace(/\./g,"");
        return this.height;
      }
    },
    computed: {
      ...mapState('user', [
        'showEventBlock',
        'general',
        'meta'
      ]),
      ...mapState ('app', [
        'locale'
      ]),
      sortedCountries: function() {
        function compare(a, b) {
          if (a.name < b.name) {
              return -1;
          }
          if (a.name > b.name) {
              return 1;
          }
          return 0;
        }
        return this.countries.sort(compare);
      },
      eventBlock() {
        return this.showEventBlock;
      },
      days() {
        let days = [];
        for (let index = 1; index <= 31; index++) {
            days.push(index);
        }
        return days;
      },
      years() {
        let years = [];
        for (let index = 2006; index >= 1940; index--) {
            years.push(index);
        }
        return years;
      },
      birthdate() {
        return this.day + '/' + this.month  + '/' + this.year;
      },
      bmi() {
        return this.weight / (this.height / 100) / (this.height / 100);
      },
      originFatherNotDefault() {
        return ((this.originFather && this.originFather != this.$i18n.t('placeholder.originFather')) ? true : false);
      },
      originMotherNotDefault() {
        return ((this.originMother && this.originMother != this.$i18n.t('placeholder.originMother')) ? true : false);
      },
      requiredOriginFatherIfOtherContinent() {
        return this.otherContinent == 'true' ? {required: true, nodefault: this.$i18n.t('placeholder.originFather')} : '';
      },
      requiredOriginMotherIfOtherContinent() {
        return this.otherContinent == 'true' ? {required: true, nodefault: this.$i18n.t('placeholder.originMother')} : '';
      },
      requiredIfOriginMotherMixed() {
        return (this.originMother == 'mixed') ? 'required' : '';
      },
      requiredIfOriginFatherMixed() {
        return (this.originFather == 'mixed') ? 'required' : '';
      },
      under18() {
        return this.validateAge(this.birthdate, 1, 18, '/');
      }
    },
  }
</script>
