<template>
  <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }"  v-on:change="setProgress()" id="medical" class="validate">
    <div class="wrapper-center" ref="form-medical">
      <modal ref="modal">
        <template #content>
          <div class="row">
            <div class="col-md-12">
              <div class="modal-content--side modal-content--side--left">
                <h2 class="modal__title">{{ $t('paying.modalTitle') }}</h2>
                <div v-html="$t('paying.modalText')"></div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="modal-content--side modal-content--side--right modal-content--bg modal-content--bg--blue modal-content--vertical modal-content--vertical--middle">
                <div class="modal-content__box">
                  <a v-on:click.prevent="modalClose" class="btn button orange">
                    <span class="text">{{ $t('button.approve') }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </template>
      </modal>
       
        <h2>{{ $t("nav.medical")}}</h2>
          <div class="row">
              <fieldset class="input_style">
                <!-- add class: show-error-message --><!-- add class: error -->
                <div class="form-group radio-group col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
    	          <div class="row">

                      <div class="pageheader">
                        <div class="col-md-7">
                            <div class="pageheader-content">
                                <h3>{{ $t('medical.title') }}</h3>
                                <p>{{ $t('medical.firstparaf') }}</p>
                                <p v-html="$t('medical.secparaf')"></p>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="alert alert-grey">
                                <h4>{{ $t('medical.questionTitle') }}</h4>
                                <p v-html="$t('medical.questionparaf')"></p>
                            </div>
                        </div>
                    </div>

                    <div class="pagecontent questions">
                        <div class="col-md-12">
                            <div id="medicalQuestions" class="questions__list">
                                <medical-question
                                    v-for="(question, index) in questions"
                                    v-bind:key="question.id"
                                    v-bind:id="question.id"
                                    v-bind:question="question"
                                    v-bind:affectedProp="setComponentsNotAffected"
                                    v-on:toggle="toggleQuestion(question)"
                                    v-on:affected="setMedicalAffection"
                                    v-bind:selected="selectedQuestionId"
                                ></medical-question>
                            </div>
                            <Field type="hidden" name="allProcessed" v-model="allProcessed" />
                            <div class="error-scroll-pos">
                                <div class="error-message" v-show="errors.allProcessed">
                                    <span class="attention error-questions">
                                        <span class="ion ion-android-alert"></span>
                                        <span>{{ $t('medical.error') }}</span>
                                    </span>
                                </div>
                            </div>

                            <div class="questions__box">
                                <div class="questions__item">
                                    <h4 class="questions__title">{{ $t('medical.useOfMedications') }}</h4>
                                    <div class="questions__content error-scroll-pos">
                                        <div class="input_wrap radio-group radio-group-button">
                        	              <Field type="radio" name="useOfMedication" id="q_no" :value="false" class="required" v-model="useOfMedication" data-progress="useOfMedication" @change="$parent.$parent.triggerGaEvent('Medisch', 'Vraag 14: ' + $t('medical.useOfMedications'), 'Nee')" />
                        	              <label for="q_no" data-test="no">{{ $t('option.no') }}</label>
                        	            </div>
                                        <div class="input_wrap radio-group radio-group-button">
                        	              <Field type="radio" name="useOfMedication" id="q_yes" :value="true" class="required" v-model="useOfMedication" @change="$parent.$parent.triggerGaEvent('Medisch', 'Vraag 14: ' + $t('medical.useOfMedications'), 'Ja')" />
                        	              <label for="q_yes" data-test="yes">{{ $t('option.yes') }}</label>
                        	            </div>
                                        <span class="error" v-show="errors.useOfMedication">{{ $t('error.required') }}</span>
                                        <div class="note-message" v-show="useOfMedication">
                                            <span class="attention">
                                                <span>{{ $t('medical.medicationSpecifications') }}</span><br/><br/>
                                                <span v-if="general.payingUser"><i>{{ $t('medical.refundWhenNotAccepted') }}</i></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="questions__item" id="medicationUsage" v-show="useOfMedication">
                                    <div class="questions__content">
                                        <div class="questions__table">
                                            <div class="questions__table__header">
                                                <div class="row row-5">
                                                    <div class="col-md-4">
                                                        <span class="label">{{ $t('label.medicationName') }}</span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="label">{{ $t('label.medicationUse') }}</span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="label">{{ $t('label.medicationFreq') }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="questions__table__row medicationRow" v-for="(medicationItem, index) in medications">
                                                <div class="row row-5 error-scroll-pos">
                                                    <div class="col-md-4">
                                                        <div class="input_wrap">
                                                            <label :for="'medications_name_'+index" class="hidden">Naam medicatie</label>
                                                            <field  type="text" :name="'medications_name_'+index" :id="'medications_name_'+index" class="form-control required medicationName" data-test="" />
                                                            <!-- <span class="error" v-show="useMedicationError">{{ $t('error.required') }}</span> -->
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="input_wrap">
                                                            <label :for="'medications_reason_'+index" class="hidden">Reden van gebruik</label>
                                                            <field :name="'medications_reason_'+index" type="text" :id="'medications_reason_'+index" class="form-control required medicationReason" data-test="" />
                                                            <span class="error" v-show="errors.useOfMedication">{{ $t('error.required') }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="input_wrap">
                                                            <label :for="'medications_frequency_'+index" class="hidden">Dosering</label>
                                                                <select :name="'medications_frequency_'+index" :id="'medications_frequency_'+index" class="form-control required medicationFreq" data-test="">
                                                                    <option :value="$t('dosage.dailyYear')">{{ $t("dosage.dailyYear") }}</option>
                                                                    <option :value="$t('dosage.dailyPeriod')">{{ $t("dosage.dailyPeriod") }}</option>
                                                                    <option :value="$t('dosage.3PerWeek')">{{ $t("dosage.3PerWeek") }}</option>
                                                                    <option :value="$t('dosage.severalMonth')">{{ $t("dosage.severalMonth") }}</option>
                                                                    <option :value="$t('dosage.severalYear')">{{ $t("dosage.severalYear") }}</option>
                                                                    <option :value="$t('dosage.other')">{{ $t("dosage.other") }}</option>
                                                                </select>
                                                           
                                                        </div>
                                                        <span class="questions__table__row__option add" v-on:click="addMedication()"></span>
                                                        <span class="questions__table__row__option remove" v-on:click="removeMedication(index)"></span>
                                                    </div>
                                                </div>
                                            </div>
                                                <span class="error" v-show="useMedicationError">{{ $t('error.required') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
	          </div>
          </fieldset>
        </div>
    </div>
    <div class="wrapper-bottom">
      <div class="app-footer clearfix">
        <router-link to="/algemeen" class="btn grey prev"><span>{{ $t("button.prev") }}</span></router-link>
        <button class="btn orange next" ><span>{{ $t("button.next") }}</span></button>
      </div>
    </div>
  </Form>
</template>
<script>
  import Vue from 'vue';
  import * as yup from 'yup';
  import { Form, Field } from 'vee-validate';
  import Modal from './../../modal/Modal.vue';
  import MedicalQuestion from '../MedicalQuestion.vue';
  import medicalQuestions from '../../../data/medical-questions.json';
  import * as types from '../../../store/mutation-types';
  import _ from 'lodash';
  import vuexMixin from '../../../mixins/vuexMixin';
  import {mapState} from 'vuex';
  

  export default {
    name: 'form-medical',
    data() {
      return {
        errorOnSubmit: false,
        useOfMedication: '',
        useMedicationError: false,

        medicalAffection: "true",
        selectedQuestionId: 1,
        lastSelectedQuestionId: 1,
        processedQuestions: [],
        questions: medicalQuestions,
        medication: {
          name: '',
          reason: '',
          frequency: '',
        },
        allProcessed: false,
        medications: [],
      }
    },
    components: {
      Form,
      Field,
      MedicalQuestion,
      Modal
    },
    setup() {

      const schema = yup.object({
        useOfMedication: yup.string().required(),
        medicalAffection: yup.string().equals(["false"]),
        allProcessed: yup.mixed().test(
          'allProcessed',
          'Not all questions are answered',
          function (val) {
            var named = document.getElementById("medicalQuestions"); 
            var questions = named.getElementsByTagName("input");
            var processed = 0;
            for (var i = 0, n = questions.length; i < n; i = i + 1) {
              if(questions[i].checked){
                processed++;
              }
            }
            var questionsLength = document.getElementsByClassName("question").length;
            return processed == questionsLength ;
          },
        )
      });
      return {
          schema
      };
    },
    mixins: [vuexMixin],
    methods: {  
      onSubmit() {
        this.medicalValidation();
      },
      medicalValidation: function(){
        // Set medication values
        this.getMedicationValues();
        // Get form values 
        let formData = this.$data;
  
        this.useMedicationError = false;
        if(formData.useOfMedication) {
          this.checkMedicationUsage();
          if(this.useMedicationError == true) {
            return false;
          }
        } else{
          this.medications = [];
        };
        formData.allProcessed = true;
        // delete formData['countries'];
        this.$store.commit('user/'+types.SET_MEDICAL, formData);

        this.$router.push('/persoonlijk');
      },
      
      checkMedicationUsage() {
        var named = document.getElementById("medicationUsage"); 
          var tags = named.getElementsByTagName("input","select");
          for (var i = 0, n = tags.length; i < n; i = i + 1) {
            if(tags[i].value == ""){
              this.useMedicationError = true;
            }
          }
          return false;
      },
      getMedicationValues() {
        let medicationName = document.getElementsByClassName("medicationName");
        let medicationReason = document.getElementsByClassName("medicationReason");
        let medicationFreq = document.getElementsByClassName("medicationFreq");
        let medications = [];
        for(var i = 0; i < medicationName.length; i++)
        {
          let medication = [];
          medication = {
            'name' : medicationName[i].value,
            'reason' : medicationReason[i].value,
            'frequency' : medicationFreq[i].value,
          };
          medications[i] = medication;
        }
        this.medications = medications;
      },
      setMedicationValues() {
        let medicationName = document.getElementsByClassName("medicationName");
        for(var i = 0; i < medicationName.length; i++)
        {
          medicationName[i].value = this.medications[i]['name'];
        }
        let medicationReason = document.getElementsByClassName("medicationReason");
        for(var i = 0; i < medicationReason.length; i++)
        {
          medicationReason[i].value =this.medications[i]['reason'];
        }
        let medicationFreq = document.getElementsByClassName("medicationFreq");
        for(var i = 0; i < medicationFreq.length; i++)
        {
          medicationFreq[i].value = this.medications[i]['frequency'];
        }
      },
      setProgress() {
          this.$parent.$parent.setProgress(25);
      },
      modalClose() {
        this.$parent.$parent.triggerGaEvent('Algemeen', 'Pop-up: Veld over betaling 35+', 'Click')
        this.$refs['modal'].close();
      },
      addMedication: function () {
          this.medications.push(this.medication);
      },
      removeMedication: function (index) {
          this.medications.splice(this.medications, index);
      },
      setMedicalAffection(affected) {
        this.medicalAffection = affected;
          if (affected === false) {
            // go to next question if any and store current selected one
              this.processedQuestions.concat(this.selectedQuestionId);
              this.selectedQuestionId++;
          } else {
            // this.medicalAffection = affected;
              let index = this.processedQuestions.indexOf(this.selectedQuestionId);
              if (index > -1) {
                  this.processedQuestions.splice(index, 1);
              }
          }
      },
      toggleQuestion(question) {
          // if weve got no medical affection than we can change the question
          if (!this.medicalAffection) {
              // If we click the same question toggle to 0, no questions close current
              if (this.selectedQuestionId == question.id) {
                  this.selectedQuestionId = 0;
              } else {
                  this.selectedQuestionId = question.id;
              }
              // last selected to open again, if needed
              this.lastSelectedQuestionId = question.id;
          }
      },
    },
    created() {
      
      this.fillDataFromStore(this.medical);
      this.$nextTick(function () {
        this.setProgress();
      });
      // check if medications is empty if so insert empty first
      if (!this.medications.length) {
        this.addMedication();
      }
    },
    mounted() {
      if (this.general.payingUser){
        this.$parent.$parent.triggerGaEvent('Algemeen', 'Pop-up: Veld over betaling 35+', 'Ja')
        this.$refs['modal'].open();
      }
      this.setMedicationValues();
    },
    computed: {
      ...mapState('user', [
        'general',
        'medical',
      ]),
      processedQuestionsOnTrue() {
        return (this.questions.length == this.processedQuestions.length);
      },
      setComponentsNotAffected() {
        // set components on re-enter component all on false
        // always the case because we passed the validation earlier on
        // undefined on first load
        return (this.allProcessed) ? false : undefined;
      },
      requiredIfUseOfMedication() {
        return (this.useOfMedication === true) ? 'required' : '';
      },
    },
       
	}

</script>
