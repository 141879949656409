import {HTTP} from './http-common'

export default {
  fetchAddress (address) {
    return HTTP.get('address/complete/' + address).then((response) => {
      return response.data.data
    }).catch((error) => {
      return Promise.reject(error.response.data)
    })
  },
  getEventCode (eventCode) {
    return HTTP.get('eventcodes/' + eventCode).then((response) => {
      return response.data.data
    }).catch((error) => {
      return Promise.reject(error.response.data)
    })
  },
  saveDonor (user) {
    return HTTP.post('user', user).then((response) => {
      return response
    }).catch((error) => {
      return Promise.reject(error.response.data)
    })
  },
  checkSwabcode (data) {
    return HTTP.post('checkSwabcode', data).then((response) => {
      return response
    }).catch((error) => {
      return Promise.reject(error.response.data)
    })
  },
  saveNotifier (data) {
    return HTTP.post('saveNotifier', data).then((response) => {
      return response
    }).catch((error) => {
      return Promise.reject(error.response.data)
    })
  },
  saveEventDonor (user) {
    return HTTP.post('saveEventDonor', user).then((response) => {
      return response
    }).catch((error) => {
      return Promise.reject(error.response.data)
    })
  },
  getEventDonor (hash) {
    return HTTP.get('getEventDonor?hash=' + hash).then((response) => {
      return response.data
    }).catch((error) => {
      return Promise.reject(error.response.data)
    })
  },
  setPayment (paymentMethod) {
    return HTTP.post('payment', paymentMethod).then((response) => {
      return response.data
    }).catch((error) => {
      return Promise.reject(error.response.data)
    })
  },
  paymentStatus (transaction) {
    return HTTP.post('payment/status', transaction).then((response) => {
      return response.data
    }).catch((error) => {
      return Promise.reject(error.response.data)
    })
  },
  saveYoungDonor (user) {
    return HTTP.post('saveYoungDonor', user).then((response) => {
      return response
    }).catch((error) => {
      return Promise.reject(error.response.data)
    })
  }
}
