<template>
    <div class="main">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
                    <div class="wrapper-top">
                        <div class="wizard-navigation"> 
                            <ul role="tablist" class="wizard-nav wizard-nav-pills">
                                <li class="done"><span class="number"><strong>1</strong></span> <span class="title">{{ $t('nav.general') }}</span></li>
                                <li class="done"><span class="number"><strong>2</strong></span> <span class="title">{{ $t('nav.medical') }}</span></li>
                                <li class="done"><span class="number"><strong>3</strong></span> <span class="title">{{ $t('nav.personal') }}</span></li>
                                <li class="done"><span class="number"><strong>4</strong></span> <span class="title">{{ $t('nav.final') }}</span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="wrapper">
                        
                        <div class="row">
                            <div class="col-md-10 col-md-offset-1">
                                <div class="app-content app-content-centered">
                                    <h2 style="display:block;">{{ $t('title.signup_error') }}</h2>
                                    <br/>
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" class="svg-icon svg-icon-false">
                                        <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                                        <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                                        <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                                    </svg>                                    
                                    <p>{{ $t('text.signup_error') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue';
    
	export default {
        name: 'form-error',
        metaInfo() {
            return {
                title: Vue.i18n.translate("meta-title.signuperror"),
            }
        },
        computed: {
        }
	}
</script>
