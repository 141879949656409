<template>
    <div class="main">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
                    <div class="wrapper-top">
                        <div class="wizard-navigation"> 
                            <ul role="tablist" class="wizard-nav wizard-nav-pills">
                                <li class="done"><span class="number"><strong>1</strong></span> <span class="title">{{ $t('nav.general') }}</span></li>
                                <li class="done"><span class="number"><strong>2</strong></span> <span class="title">{{ $t('nav.medical') }}</span></li>
                                <li class="done"><span class="number"><strong>3</strong></span> <span class="title">{{ $t('nav.personal') }}</span></li>
                                <li class="done"><span class="number"><strong>4</strong></span> <span class="title">{{ $t('nav.final') }}</span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="wrapper">
                        
                        <div class="row">
                            <div class="col-md-10 col-md-offset-1">
                                <div class="app-content app-content-centered">
                                    <h2>{{ $t('title.signup_thanks') }}</h2>
                                    <p>{{ $t('text.signup_thanks') }}</p>
                                    <div class="button-area">
                                        <a :href="$t('share.facebook_link')" target="popup" class="btn facebook"><span class="fa fa-facebook"></span> {{ $t('share.facebook') }}</a>
                                        <a :href="$t('share.twitter_link')" target="popup" class="btn twitter"><span class="fa fa-twitter"></span> {{ $t('share.twitter') }}</a>
                                        <a :href="$t('share.twibbon_link')" target="popup" class="btn orange"><span class="fa fa-twibbon"><img src="/assets/images/twibbon_icon.png" alt="Twibbon" class="img-responsive" /></span> {{ $t('share.twibbon') }}</a>
                                    </div>
                                    <p v-html="$t('footer.signup_thanks')"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue';
    
	export default {
        name: 'form-thanks',
        // metaInfo() {
        //     return {
        //         title: Vue.i18n.translate("meta-title.signupthanks"),
        //     }
        // }
	}
</script>
